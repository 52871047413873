import { addExam, updateExam, getExams, deleteExam } from '../../services';
import { actions } from './actions';

const setExams = (exams) => ({ type: actions.SET_EXAMS, payload: exams });

export const createExam = (exam) => {
  return function (dispatch) {
    return addExam(exam).then(() => dispatch(getAllExams()));
  };
};

export const putExam = (id, exam) => {
  return function (dispatch) {
    return updateExam(id, exam).then(() => dispatch(getAllExams()));
  };
};

export const getAllExams = () => {
  return function (dispatch) {
    return getExams().then((exams) => dispatch(setExams(exams)));
  };
};

export const removeExam = (id) => {
  return function (dispatch) {
    return deleteExam(id).then(() => dispatch(getAllExams()));
  };
};
