import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { genAvatar } from '../../utils';
import './styles.scss';

export function Message() {
  const { message } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!message) return;

    if (message.type === 'wellcome')
      toast(() => (
        <div className='wellcome'>
          <div className='wellcome__avatar'>
            {message.img ? (
              <img src={message.avatar} alt='User profile' className='img-fluid rounded-circle' />
            ) : (
              genAvatar(message.avatar)
            )}
          </div>
          <span className='wellcome__message'>{message.message}</span>
        </div>
      ));

    if (message.type === 'success') toast.success(message.message);

    if (message.type === 'error') toast.error(message.message);

    if (message.type === 'promise') {
      toast.promise(message.promise, {
        loading: 'Cargando...',
        success: message.message.success,
        error: message.message.error,
      });
    }
  }, [dispatch, message]);

  return <Toaster />;
}
