import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getAllTickets, updateTicketStatus } from "../services";

export const useTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const updateTicket = (id) => {
    return async () => {
      try {
        const ticket = await updateTicketStatus(id);
        const newTickets = tickets.map((item) => {
          if (item.id === ticket.id) {
            return ticket;
          }
          return item;
        });
        setTickets(newTickets);
      } catch (error) {
        toast.error("¡Ha ocurrido un error!");
      }
    };
  };

  useEffect(() => {
    async function fetchTickets() {
      setIsLoading(true);
      const data = await getAllTickets();
      setTickets(data);
      try {
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchTickets();
  }, []);

  return { tickets, isLoading, isError, updateTicket };
};
