import { MainLayout } from "../../layouts";
import { TicketsHeader } from "./modules/header";
import { TicketList } from "./modules/list";

export const Tickets = () => {
  return (
    <MainLayout>
      <TicketsHeader />

      <TicketList />
    </MainLayout>
  );
};
