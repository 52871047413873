import { actions } from './actions';
import { generateCertificates } from '../../services';

export const getAllCertificates = (data, startDate, endDate) => (dispatch) =>
  generateCertificates(data, startDate, endDate).then((certificates) =>
    dispatch({
      type: actions.SET_CERTIFICATES,
      payload: certificates,
    })
  );
