import { useExams } from "../../../../hooks/useExams";
import { Field, ErrorMessage } from "formik";

const newModule = {
  title: "",
  quizId: "",
  classes: [],
};

export const Module = ({ index, arrayHelpersModule }) => {
  const { exams } = useExams();

  return (
    <div className="form-group">
      {index > 0 && <hr />}

      <button
        type="button"
        className="btn btn-link"
        onClick={() => arrayHelpersModule.insert(index, newModule)}
      >
        <i className="uil uil-folder-plus"></i> Agregar Modulo
      </button>

      <div className="form-group">
        <label className="course__form-label" htmlFor="course__form-title">
          <i className="uil uil-bookmark-full"></i> Titulo del Modulo
        </label>

        <button
          type="button"
          onClick={() => arrayHelpersModule.remove(index)}
          className="btn btn-link course__form-collapse-btn"
          style={{ position: "absolute", right: "1rem" }}
        >
          <i className="uil uil-trash-alt" style={{ color: "red" }}></i>
        </button>
        <Field className="form-control" type="text" name={`modules[${index}].title`} />
        <ErrorMessage
          name={`modules[${index}].title`}
          component="small"
          className="form-error-message"
        />

        <div className="form-group col-md-6 mt-2">
          <label className="course__form-label" htmlFor="course__form-title">
            <i className="uil book-open"></i> Examen ID
          </label>
          <Field
            as="select"
            id="course__form-title"
            className="exam_form-course form-control"
            name={`modules[${index}].quizId`}
          >
            <option hidden>Selecciona examen</option>
            {exams && exams?.length
              ? exams?.map((exam) => (
                  <option key={exam.id} value={exam.id || ""}>
                    {exam.name}
                  </option>
                ))
              : "No hay examenes"}
          </Field>
          <ErrorMessage
            name={`modules[${index}].quizId`}
            component="small"
            className="form-error-message"
          />
        </div>

        {/* <div className='form-group col-md-6 mt-2'>
          <label className='course__form-label' htmlFor='course__form-title'><i className="uil book-open"></i> {' '} Examen ID</label>
          <input className='form-control' type="text" name='examenID' value={data?.examenID} onChange={({ target }) => handleOnChangeTopic(target, index)} />
        </div> */}
      </div>
    </div>
  );
};
