import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { logout } from '../../../store/actions';
import { removeUserFromLocalStorage } from '../../../utils';
import './styles.scss';

const routes = [
  {
    path: '/',
    icon: 'parcel',
    text: 'Cursos',
  },
  {
    path: '/registros',
    icon: 'qrcode-scan',
    text: 'Registros',
  },
  /* {
    path: '/cursos',
    icon: 'parcel',
    text: 'Cursos',
  }, */
  {
    path: '/examenes',
    icon: 'file-bookmark-alt',
    text: 'Examenes',
  },
  {
    path: '/eventos',
    icon: 'calendar-alt',
    text: 'Eventos',
  },
  {
    path: '/usuarios',
    icon: 'users-alt',
    text: 'Usuarios',
  },
  {
    path: '/certificados',
    icon: 'newspaper',
    text: 'Certificados',
  },
  {
    path: '/reportes',
    icon: 'clipboard-alt',
    text: 'Reportes',
  },
  {
    path: '/tickets',
    icon: 'ticket',
    text: 'Tickets',
  },
];

export const NavList = () => {
  const dispatch = useDispatch();

  return (
    <ul className='dashboard__sidebar-list'>
      {routes.map(({ path, icon, text }, index) => (
        <li key={index} className='dashboard__sidebar-list-item'>
          <NavLink exact to={path} className='dashboard__sidebar-list-link'>
            <span className='dashboard__sidebar-list-icon'>
              <i className={'uil uil-' + icon} />
            </span>
            <div className='dashboard__sidebar-list-text'>{text}</div>
          </NavLink>
        </li>
      ))}
      <li className='dashboard__sidebar-list-item'>
        <Link
          to='/login'
          onClick={() => {
            dispatch(logout());
            removeUserFromLocalStorage();
          }}
          className='dashboard__sidebar-list-link'
        >
          <span className='dashboard__sidebar-list-icon'>
            <i className='fa-solid fa-right-from-bracket'></i>
          </span>
          <div className='dashboard__sidebar-list-text'>Salir</div>
        </Link>
      </li>
    </ul>
  );
};
