import moment from "moment";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEventForm } from "../../hooks/useEventForm";
import { useEvent } from "../../../../hooks/useEvents";
import "./styles.scss";

const initialState = {
  title: "",
  description: "",
  body: "",
  thumb: "",
  imparted: "",
  date: "",
  hour_start: "",
  hour_end: "",
  location: "",
};

export const AddEdit = ({ eventIdModal, eventSelected, handleSelectEvent }) => {
  const { eventSchema } = useEventForm();
  const { addEvent, updateEvent } = useEvent();
  const [thumb, setThumb] = useState(null);

  const handleChangeThumb = (cb) => {
    return (e) => {
      const file = e.target.files[0];
      if (!file) return;
      const formats = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
      if (!formats.includes(file.type)) return;
      setThumb(file);
      cb(e.target.name, `${file.name}-${file.type}`);
    };
  };

  const adapter = (event) => {
    if (!event) return null;

    const normalizedEvent = {
      ...event,
      date: moment(event.date).format("YYYY-MM-DD"),
      categoryId: event.category?.id?.toString(),
    };
    return normalizedEvent;
  };

  const handleSubmit = (data, { resetForm }) => {
    const formData = new FormData();

    for (const key in data) {
      if (key !== "thumb") {
        formData.append(key, data[key]);
      }
    }
    formData.append("thumb", thumb);

    let res = null;

    if (eventSelected) {
      res = updateEvent(data.id, formData);
    } else {
      res = addEvent(formData);
    }

    res
      .then(() => {
        if (eventSelected) {
          handleSelectEvent(null);
        } else {
          resetForm();
        }
        window.bootstrap.Modal.getInstance(
          document.getElementById(eventIdModal)
        ).hide();
      })
      .catch((err) => {
        console.error(err.message);
        alert("Error");
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={adapter(eventSelected) || initialState}
      validationSchema={eventSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, resetForm }) => {
        const handleCancel = () => {
          if (eventSelected) {
            handleSelectEvent(null);
          } else {
            resetForm();
          }
        };

        return (
          <Form className="event__form">
            <div className="form-group">
              <label className="event__form-label" htmlFor="event_form-title">
                <i className="uil uil-bell"></i> Nombre del Evento
              </label>
              <Field
                id="event_form-title"
                className="event__form-title form-control"
                name="title"
                type="text"
              />
              <ErrorMessage
                name="title"
                component="small"
                className="form-error-message"
              />
            </div>

            <div className="form-group">
              <label
                className="event__form-label"
                htmlFor="event_form-description"
              >
                <i className="uil uil-edit-alt"></i> Descripción Breve
              </label>
              <Field
                as="textarea"
                id="event_form-description"
                className="event_form-description form-control"
                name="description"
                type="text"
                rows="3"
              />
              <ErrorMessage
                name="description"
                component="small"
                className="form-error-message"
              />
            </div>

            <div className="form-group">
              <label className="event__form-label" htmlFor="event_form-body">
                <i className="uil uil-edit-alt"></i> Descripción
              </label>
              <Field
                as="textarea"
                id="event_form-body"
                className="event_form-body form-control"
                name="body"
                type="text"
                rows="3"
              />
              <ErrorMessage
                name="body"
                component="small"
                className="form-error-message"
              />
            </div>

            {/* <div className='form-group'>
              <label className='event__form-label' htmlFor='event_form-thumb'>
                <i className='uil uil-image-upload'></i> Url de la Imagen
              </label>
              <Field
                id='event_form-thumb'
                className='event_form-thumb form-control'
                name='thumb'
                type='text'
              />
              <ErrorMessage name='thumb' component='small' className='form-error-message' />
            </div> */}
            <div className="form-group">
              <label className="course__form-label">
                <i className="uil uil-image-plus"></i> Imagen del curso
              </label>
              <label className="course__form-thumb" htmlFor="event_form-thumb">
                {values.thumb
                  ? values.thumb
                  : !thumb
                  ? "Seleccionar imagen"
                  : thumb.name}
              </label>
              <input
                id="event_form-thumb"
                type="file"
                name="thumb"
                multiple={false}
                accept="image/png, image/jpeg, image/webp, image/jpg"
                onChange={handleChangeThumb(setFieldValue)}
              />
              <ErrorMessage
                name="thumb"
                component="small"
                className="form-error-message"
              />
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label
                  className="event__form-label"
                  htmlFor="event_form-imparted"
                >
                  <i className="uil uil-user-md"></i> Impartido por
                </label>
                <Field
                  id="event_form-imparted"
                  className="event_form-imparted form-control"
                  name="imparted"
                  type="text"
                />
                <ErrorMessage
                  name="imparted"
                  component="small"
                  className="form-error-message"
                />
              </div>

              <div className="form-group col-md-6">
                <label className="event__form-label" htmlFor="event_form-date">
                  <i className="uil uil-calendar-alt"></i> Fecha
                </label>
                <Field
                  id="event_form-date"
                  className="event_form-date form-control"
                  name="date"
                  type="date"
                />
                <ErrorMessage
                  name="date"
                  component="small"
                  className="form-error-message"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label
                  className="event__form-label"
                  htmlFor="event_form-hour-start"
                >
                  <i className="uil uil-clock-eight"></i> Hora de Inicio
                </label>
                <Field
                  id="event_form-hour-start"
                  className="event_form-hour-start form-control"
                  name="hour_start"
                  type="time"
                />
                <ErrorMessage
                  name="hour_start"
                  component="small"
                  className="form-error-message"
                />
              </div>

              <div className="form-group col-md-6">
                <label
                  className="event__form-label"
                  htmlFor="event_form-hour-end"
                >
                  <i className="uil uil-clock-eight"></i> Hora Final
                </label>
                <Field
                  id="event_form-hour-end"
                  className="event_form-hour-end form-control"
                  name="hour_end"
                  type="time"
                />
                <ErrorMessage
                  name="hour_end"
                  component="small"
                  className="form-error-message"
                />
              </div>
            </div>

            <div className="form-group">
              <label
                className="event__form-label"
                htmlFor="event_form-location"
              >
                <i className="uil uil-location-pin-alt"></i> Lugar
              </label>
              <Field
                id="event_form-location"
                className="event_form-location form-control"
                name="location"
                type="text"
              />
              <ErrorMessage
                name="location"
                component="small"
                className="form-error-message"
              />
            </div>
            {/* 
            <div className='form-group'>
              <label className='event__form-label' htmlFor='event_form-category'>
                <i className='uil uil-tag-alt'></i> Categoria
              </label>
              <Field
                as='select'
                id='event_form-category'
                className='event_form-category form-control'
                name='categoryId'
              >
                <option value='4'>Eventos anteriores</option>
                <option value='3'>Lo que está pasando</option>
                <option value='2'>Lo que viene</option>
              </Field>
              <ErrorMessage name='categoryId' component='small' className='form-error-message' />
            </div> */}

            <button
              type="submit"
              className="btn btn-dark event_form-submit mt-3 mr-3"
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-light course__form-submit mt-3"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
