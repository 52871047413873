import './styles.scss'

import { iconChevronRt } from '../../assets/img';

export const Loading = () => {
  return (
      <div className="loading">
        <div className="loading__container">
        <img className="loading__container-item" src={iconChevronRt} alt=">" />
        <img className="loading__container-item" src={iconChevronRt} alt=">" />
        <img className="loading__container-item" src={iconChevronRt} alt=">" />
        </div>
      </div>
  );
}