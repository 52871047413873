import axios from './axios.service';

export const getUsers = () => {
  return axios.get('/user/all').then((res) => res.users);
};

export const addUsers = (data) => {
  return axios.post('/user/upload_users', data);
};

export const addUser = (user) => {
  return axios.post('/auth/register', user);
};

export const updateUser = (id, user) => {
  return axios.put(`/user/${id}`, user);
};

export const deleteUser = (id) => {
  return axios.delete(`/user/${id}`);
};
