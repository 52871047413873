import React, { useState } from 'react';
import { useExams } from '../../../../hooks/useExams';
import { Pagination } from '../../../../components';

export const ExamList = ({ examIdModal, handleSelectExam }) => {
  const { exams, deleteExam } = useExams();

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  return (
    <div className='exam_list'>
      <div className='exams_list-grid'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {exams.length ? (
              exams?.map(
                (exam, index) =>
                  index >= initial &&
                  index <= end && (
                    <tr key={exam.id}>
                      <th data-label='#' scope='row'>
                        {index + 1}
                      </th>
                      <td data-label='Título:'>{exam.name}</td>
                      <td className='table__actions'>
                        <button
                          type='button'
                          className='btn btn-light btn-small mr-1'
                          data-bs-toggle='modal'
                          data-bs-target={`#${examIdModal}`}
                          onClick={() => handleSelectExam(exam)}
                        >
                          <i className='uil uil-pen' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-light btn-small'
                          onClick={() => deleteExam(exam.id)}
                        >
                          <i className='uil uil-trash-alt' />
                        </button>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td colSpan='3' align='center'>
                  No hay examenes disponibles
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={exams?.length} />
    </div>
  );
};
