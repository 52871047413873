import React from 'react';
import { Field, ErrorMessage } from 'formik';

export const QuestionsOptions = ({ index, idx, arrayHelpersOption }) => {
  return (
    <div className='class-content' style={{ position: 'relative' }}>
      <button
        type='button'
        onClick={() => arrayHelpersOption.remove(idx)}
        className='btn btn-link course__form-collapse-btn'
        style={{ position: 'absolute', top: '-0.5em', right: '0' }}
      >
        <i className='uil uil-trash-alt' style={{ color: 'red' }}></i>
      </button>
      <div className='form-group'>
        <label className='exam__form-label' htmlFor='exam__form-title'>
          <i className='uil uil-edit-alt'></i> Opción de Respuesta
        </label>
        <Field
          as='textarea'
          className='form-control'
          type='text'
          name={`quizQuestions[${index}].questionOptions[${idx}].answer`}
        />
        <ErrorMessage
          name={`quizQuestions[${index}].questionOptions[${idx}].answer`}
          component='small'
          className='form-error-message'
        />
      </div>

      <div className='form-group'>
        <label className='exam__form-label' htmlFor='exam_form-isCorrect'>
          <i className='uil uil-user-plus'></i> Respuesta
        </label>
        <Field
          as='select'
          id='exam_form-isCorrect'
          className='exam_form-isCorrect form-control'
          name={`quizQuestions[${index}].questionOptions[${idx}].isCorrect`}
          type='text'
        >
          <option value={''}>Incorrecta</option>
          <option value={true}>Correcta</option>
        </Field>
        <ErrorMessage
          name={`quizQuestions[${index}].questionOptions[${idx}].isCorrect`}
          component='small'
          className='form-error-message'
        />
      </div>
    </div>
  );
};
