import { useState } from 'react';
import { useUsers } from '../../../../hooks/useUsers';
import { Pagination } from '../../../../components';
import { genAvatar } from '../../../../utils';
import './styles.scss';

export const UserList = ({ userIdModal, handleSelectUser }) => {
  const { users, deleteUser } = useUsers();

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  return (
    <div className='users__list'>
      <div className='users_list-grid'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Avatar</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Empresa</th>
              <th>Estado</th>
              <th>Rol</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {users && users.length > 0 ? (
              users.map(
                (user, index) =>
                  index >= initial &&
                  index <= end && (
                    <tr key={user.id}>
                      <th data-label='#' scope='row'>
                        {index + 1}
                      </th>
                      <td data-label='Avatar:'>
                        {user.avatar ? (
                          <img src={user.avatar} alt='User profile' className='avatar__profile' />
                        ) : (
                          <div className='avatar__profile'>
                            <span>{genAvatar(`${user.name} ${user.lastname}`)}</span>
                          </div>
                        )}
                      </td>
                      <td data-label='Nombre:'>{`${user.name} ${user.lastname}`}</td>
                      <td data-label='Email:'>{user.email}</td>

                      <td data-label='Empresa:'>{user?.company?.business_name || 'N.A'}</td>
                      <td data-label='Estado:'>
                        <span className={`user__status ${user.active ? 'active' : ''}`}>
                          {user.active ? 'Activo' : 'Suspendido'}
                        </span>
                      </td>
                      <td data-label='Rol:'>{user.role?.name}</td>
                      <td data-label='Acciones' className='table__actions'>
                        <button
                          type='button'
                          className='btn btn-light btn-small mr-1'
                          data-bs-toggle='modal'
                          data-bs-target={`#${userIdModal}`}
                          onClick={() => handleSelectUser(user)}
                        >
                          <i className='uil uil-pen' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-light btn-small'
                          onClick={() => deleteUser(user.id)}
                        >
                          <i className='uil uil-trash-alt' />
                        </button>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td colSpan='7' align='center'>
                  No hay usuarios disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={users?.length} />
    </div>
  );
};
