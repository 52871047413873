import { Fragment, useState } from "react";
import { Loading, Pagination } from "../../../../components";
import { useTickets } from "../../../../hooks/useTickets";

export const TicketList = () => {
  const { tickets, isLoading, isError, updateTicket } = useTickets();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleVisibility = (index) => {
    return () => {
      if (index === activeIndex) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(index);
      }
    };
  };

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  if (isLoading && !isError) {
    return <Loading />;
  }

  return (
    <div className="events_list">
      <div className="events_list-grid">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Área</th>
              <th>Usuario</th>
              <th>Email</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && isError && (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  ¡Ha ocurrido un error!
                </td>
              </tr>
            )}

            {!isLoading &&
              !isError &&
              tickets.length > 0 &&
              tickets.map((ticket, index) => {
                return (
                  index >= initial &&
                  index <= end && (
                    <Fragment key={ticket.id}>
                      <tr>
                        <th data-label="#" scope="row">
                          <button onClick={handleVisibility(index)} className="drop-button">
                            {activeIndex === index && <i className="fa-solid fa-caret-up" />}
                            {activeIndex !== index && <i className="fa-solid fa-caret-down" />}
                          </button>
                          {index + 1}
                        </th>
                        <td data-label="Área:">{ticket.area}</td>
                        <td data-label="Usuario">
                          {ticket.user.name} {ticket.user.lastname}
                        </td>
                        <td data-label="Email">{ticket.user.email}</td>
                        <td data-label="Fecha:">
                          {new Date(ticket.created_at).toLocaleDateString()}
                        </td>
                        <td data-label="Estado:">
                          {ticket.status === "open" ? "Abierto" : "Cerrado"}
                        </td>
                        <td className="table__actions">
                          <button
                            type="button"
                            disabled={ticket.status === "close"}
                            className="btn btn-light btn-small"
                            onClick={updateTicket(ticket.id)}
                          >
                            Cerrar
                          </button>
                        </td>
                      </tr>
                      {activeIndex === index && (
                        <tr>
                          <td colSpan={7} style={{ padding: "16px 16px" }}>
                            <h6 style={{ fontWeight: "bold" }}>Descripción:</h6>
                            <p style={{ margin: 0, paddingTop: 8 }}>{ticket.description}</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                );
              })}

            {!isLoading && !isError && tickets.length === 0 && (
              <tr>
                <td colSpan="8" align="center">
                  Aún no hay tickets
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!isLoading && !isError && tickets.length > 0 && (
        <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={tickets?.length} />
      )}
    </div>
  );
};
