import React, { useState } from 'react';
import { CATEGORIES } from '../../../../utils';
import { Pagination } from '../../../../components';
import { useRegisters } from '../../../../hooks/useRegisters';

export function RegistersList({ registerIdModal, category, data, handleSelectRegister }) {
  const { deleteRegister } = useRegisters();

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  return (
    <div className='registers_list'>
      <div className='registers_list-grid'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Instructor</th>
              {category === CATEGORIES.proteccionCivil && <th>Estado</th>}
              {category === CATEGORIES.proteccionCivil && <th>Municipio</th>}
              <th>Registro</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map(
                (register, index) =>
                  index >= initial &&
                  index <= end && (
                    <tr key={register.id}>
                      <th data-label='#' scope='row'>
                        {index + 1}
                      </th>
                      <td data-label='Instructor:'>
                        {register.instructor?.name} {register.instructor?.lastname}
                      </td>
                      {category === CATEGORIES.proteccionCivil && (
                        <td data-label='Estado:'>{register.state?.name}</td>
                      )}
                      {category === CATEGORIES.proteccionCivil && (
                        <td data-label='Municipio:'>{register.town?.name || 'Estatal'}</td>
                      )}
                      <td data-label='Registro:'>{register.register}</td>
                      <td className='table__actions'>
                        <button
                          className='btn btn-light btn-small mr-1'
                          data-bs-toggle='modal'
                          data-bs-target={`#${registerIdModal}`}
                          onClick={() => handleSelectRegister(register)}
                        >
                          <i className='uil uil-pen' />
                        </button>
                        <button
                          className='btn btn-light btn-small'
                          onClick={() => deleteRegister(register.id, register.categoryId)}
                        >
                          <i className='uil uil-trash-alt' />
                        </button>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td colSpan='6' align='center'>
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={data?.length} />
    </div>
  );
}
