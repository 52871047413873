import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { createRegister, putRegister, removeRegister } from '../store/actions';

export function useRegisters() {
  const { registers } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteRegister = (id, categoryId) =>
    Swal.fire({
      title: '¿Eliminar?',
      text: '¡El registro será eliminado!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeRegister(id, categoryId))
          .then(() => {
            Swal.fire('¡Eliminado!', 'El registro ha sido eliminado exitosamente.', 'success');
          })
          .catch((err) => {
            console.error(err.message);

            if (err.response.status === 401) return;

            Swal.fire('¡Error!', 'Ha ocurrido un error.', 'error');
          });
      }
    });

  const addRegister = (register) => dispatch(createRegister(register));

  const updateRegister = (id, register) => dispatch(putRegister(id, register));

  return { registers, addRegister, updateRegister, deleteRegister };
}
