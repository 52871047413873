import { actions } from '../actions/actions';

const initialState = {
  usersCoursesReports: [],
  coursesReports: [],
};

export const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SET_REPORTS:
      return {
        usersCoursesReports: payload.usersCoursesReports,
        coursesReports: payload.coursesReports,
      };

    case actions.SET_COURSES_REPORT:
      return {
        ...state,
        coursesReports: payload,
      };

    default:
      return state;
  }
};
