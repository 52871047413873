import React from "react";

export function TicketsHeader() {
  return (
    <div className="events__header">
      <h2 className="events__title">
        <i className="uil uil-ticket" /> Tickets
      </h2>
    </div>
  );
}
