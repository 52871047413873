import React from 'react';

export function EventsHeader({ eventIdModal, handleSelectEvent }) {
  return (
    <div className='events__header'>
      <h2 className='events__title'>
        <i className='uil uil-calendar-alt' /> Eventos
      </h2>

      <div className='events__add'>
        <button
          onClick={() => handleSelectEvent(null)}
          className='btn btn-dark events__add-btn'
          data-bs-toggle='modal'
          data-bs-target={`#${eventIdModal}`}
        >
          <i className='uil uil-plus events__add-icon'></i>
          <span className='events__add-text'>Agregar Evento</span>
        </button>
      </div>
    </div>
  );
}
