import { addEvent, updateEvent, getEvents, deleteEvent } from '../../services';
import { actions } from './actions';

const setEvents = (events) => ({ type: actions.SET_EVENTS, payload: events });

export const createEvent = (event) => {
  return function (dispatch) {
    return addEvent(event).then(() => dispatch(getAllEvents()));
  };
};

export const putEvent = (id, event) => {
  return function (dispatch) {
    return updateEvent(id, event).then(() => dispatch(getAllEvents()));
  };
};

export const getAllEvents = () => {
  return function (dispatch) {
    return getEvents().then((events) => dispatch(setEvents(events)));
  };
};

export const removeEvents = (id) => {
  return function (dispatch) {
    return deleteEvent(id).then(() => dispatch(getAllEvents()));
  };
};
