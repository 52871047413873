import { Link } from 'react-router-dom';
import { logoTransparent } from '../../../assets/img';
import './styles.scss';

export const Logo = () => {
  return (
    <div className='dashboard_topbar-logo'>
      <Link to='/'>
        <img className='dashboard_topbar-logo-img' src={logoTransparent} alt='Proceso Seguro' />
      </Link>
    </div>
  );
};
