import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../store/actions';
import { useExams } from '../../../hooks/useExams';

const initialState = {
  name: '',
};

const question = {
  question: '',
  questionOptions: [],
};

export function useExamForm({ examIdModal, examSelected, handleSelectExam }) {
  const examSchema = Yup.object().shape({
    name: Yup.string().required('Este campo es requerido.'),
    quizQuestions: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required('Este campo es requerido.'),
          questionOptions: Yup.array()
            .of(
              Yup.object().shape({
                answer: Yup.string().required('Este campo es requerido.'),
              })
            )
            .required('Es necesario agregar como mínimo 2 opciones de respuesta.')
            .min(2, 'Es necesario agregar como mínimo 2 opciones de respuesta.'),
        })
      )
      .required('Es necesario agregar como mínimo una pregunta.')
      .min(1, 'Es necesario agregar como mínimo una pregunta.'),
  });

  const initialValues = examSelected || initialState;
  const { addExam, updateExam } = useExams();

  const dispatch = useDispatch();

  const handleSubmit = (data, { resetForm }) => {
    let res = null;

    if (examSelected) {
      res = updateExam(examSelected.id, data);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Examen actualizado exitosamente',
            error: 'Ha ocurrido un error',
          },
          promise: res,
        })
      );
    } else {
      res = addExam(data);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Examen creado exitosamente',
            error: 'Ha ocurrido un error',
          },
          promise: res,
        })
      );
    }

    res
      .then(() => {
        if (examSelected) {
          handleSelectExam(null);
        } else {
          resetForm();
        }
        window.bootstrap.Modal.getInstance(document.getElementById(examIdModal)).hide();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const renderquizQuestionArrayErrors = (errors) =>
    typeof errors.quizQuestions === 'string' && (
      <small className='form-error-message'>{errors.quizQuestions}</small>
    );

  const handleCancel = (resetForm) => {
    if (examSelected) {
      handleSelectExam(null);
    } else {
      resetForm();
    }
  };

  return {
    examSchema,
    initialValues,
    question,
    handleSubmit,
    renderquizQuestionArrayErrors,
    handleCancel,
  };
}
