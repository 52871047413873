import { actions } from '../actions/actions';

// roles: 1: autor, 2: editor, 3: administrador

const initialState = [];

export const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SET_USERS:
      return payload;

    default:
      return state;
  }
};
