import { combineReducers } from 'redux';
import { app } from './app.reducer';
import { auth } from './auth.reducer';
import { users } from './users.reducer';
import { exams } from './exams.reducer';
import { events } from './events.reducer';
import { courses } from './courses.reducer';
import { registers } from './registers.reducer';
import { certificates } from './certificates.reducer';
import { reports } from './reports.reducer';

export const rootReducers = combineReducers({
  app,
  auth,
  users,
  exams,
  events,
  courses,
  registers,
  certificates,
  reports,
});
