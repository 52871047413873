import { useApp } from '../../hooks/useApp';
import * as includes from '../../includes';
import './styles.scss';

export const MainLayout = ({ children }) => {
  const { expand, setIsExpanded } = useApp();

  return (
    <div className='dashboard'>
      <includes.Sidebar expand={expand} />

      <div className='dashboard__container'>
        <includes.Topbar setIsExpanded={setIsExpanded} expand={expand} />
        <div className='dashboard__content'>{children}</div>
      </div>
    </div>
  );
};
