import React, { useState } from 'react';
import { REPORT_CATEGORY } from '../../../../utils';

export function ReportsHeader({ handleChangeReportCategory }) {
  return (
    <div className='reports__header'>
      <h2 className='reports__title'>
        <i className='uil uil-clipboard-alt' /> Reportes
      </h2>
      <div className='reports'>
        <div className='mr-3'>
          <button
            className='btn btn-warning mb-1 mr-3'
            onClick={() => handleChangeReportCategory(REPORT_CATEGORY.usuarios)}
          >
            Usuarios
          </button>
          <button
            className='btn btn-warning'
            onClick={() => handleChangeReportCategory(REPORT_CATEGORY.cursos)}
          >
            Cursos
          </button>
        </div>
      </div>
    </div>
  );
}
