import { getCoursesReports, getUsersCoursesReports, updateUserCourseAttemps } from "../../services";
import { actions } from "./actions";

const setUsersCoursesReports = (reports) => ({
  type: actions.SET_REPORTS,
  payload: {
    usersCoursesReports: reports?.[0]?.dataCourses,
    // coursesReports: reports?.[1],
    coursesReports: [],
  },
});

const setCoursesReport = (reports) => ({
  type: actions.SET_COURSES_REPORT,
  payload: reports,
});

export const getUsersCoursesReport = () => (dispatch) => {
  Promise.all([getUsersCoursesReports()])
    .then((reports) => {
      dispatch(setUsersCoursesReports(reports));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getCoursesReport = (year, stateId) => (dispatch) => {
  getCoursesReports(year, stateId)
    .then((reports) => dispatch(setCoursesReport(reports?.reportData)))
    .catch((err) => console.error(err));
};

export const resetUserCourseAttemps = (courseId, userId) => (dispatch) => {
  updateUserCourseAttemps(courseId, userId).then(() => {
    dispatch(getUsersCoursesReport());
  });
};
