import React from 'react';
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { useCourses } from '../../../hooks/useCourses';

export function ReportsCoursesList() {
  const { coursesReports, year, handlePrevYear, handleNextYear, stateId, handleChange, states } =
    useCourses();

  return (
    <>
      <div className='row align-items-end gap-3'>
        <div className='col-sm-6'>
          <div className='d-flex flex-column justify-content-center'>
            <h2 className='m-0'>{year}</h2>
            <div className='d-flex gap-3'>
              <button onClick={handlePrevYear} className='btn btn-sm btn-secondary'>
                <span className='font-weight-bold'>-</span>
              </button>
              <button onClick={handleNextYear} className='btn btn-sm btn-secondary'>
                <span className='font-weight-bold'>+</span>
              </button>
            </div>
          </div>
        </div>

        <div className='col-sm-5'>
          <label htmlFor='state-select' className='form-label input-label'>
            Estado:
          </label>
          <select
            id='state-select'
            className='form-select justify-self-start'
            value={stateId}
            onChange={handleChange}
          >
            <option value=''>Seleccione un estado</option>
            {states?.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div
        style={{
          boxSizing: 'border-box',
          margin: '2rem 0 1rem 0',
          padding: '0',
          overflow: 'auto',
          width: '100vw',
          maxWidth: 'calc(100vw - 85px)',
        }}
      >
        <DataGrid
          id='grid'
          /* dataSource={customColumns(state.collaborators)} */
          dataSource={coursesReports}
          keyExpr='id'
          showBorders={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode='widget'
          columnMinWidth={50}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          hoverStateEnabled={true}
          showColumnLines={true}
          rowAlternationEnabled={true}
          /* onExporting={onExporting} */
          /* onExported={onExported} */
        >
          <StateStoring enabled={true} type='localStorage' storageKey='POLGridCollaborators' />
          <FilterRow visible={true} applyFilter='auto' />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} emptyPanelText='Arrastra las columnas aqui para agrupar' />
          <SearchPanel visible={true} highlightCaseSensitive={false} />
          <Selection mode='single' />
          <Grouping autoExpandAll={true} />
          <ColumnChooser enabled={true} />
          <ColumnFixing enabled={true} />
          <Export
            enabled={true}
            /* fileName={`Colaboradores_${getTodayDateString()}`} */
            fileName={`Colaboradores_${Date.now().toLocaleString()}`}
            allowExportSelectedData={false}
          />
          <Summary>
            <TotalItem
              column='course'
              displayFormat='Total de Registros: {0}'
              summaryType='count'
            />
          </Summary>
          <SortByGroupSummaryInfo summaryItem='count' />

          <Column
            caption='Cursos'
            dataField='course'
            // defaultSortOrder='asc'
            /* cellRender={customFullName} */
          />
          <Column caption='Enero' dataField='january' visible={true} />
          <Column caption='Febrero' dataField='february' visible={true} />
          <Column caption='Marzo' dataField='march' visible={true} />
          <Column caption='Abril' dataField='april' visible={true} />
          <Column caption='Mayo' dataField='may' visible={true} />
          <Column caption='Junio' dataField='june' visible={true} />
          <Column caption='Julio' dataField='july' visible={true} />
          <Column caption='Agosto' dataField='august' visible={true} />
          <Column caption='Septiembre' dataField='september' visible={true} />
          <Column caption='Octubre' dataField='october' visible={true} />
          <Column caption='Noviembre' dataField='november' visible={true} />
          <Column caption='Diciembre' dataField='december' visible={true} />
        </DataGrid>
      </div>
    </>
  );
}
