import React from 'react';
import { getUserFromLocalStorage } from '../utils';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = getUserFromLocalStorage();

  if (!user) return <Redirect to='/login' />;

  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
};

export default PrivateRoute;
