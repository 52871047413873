import { Menu } from './hamburger';
import { Logo } from './logo';
import './styles.scss';

export const Topbar = ({ setIsExpanded, expand }) => {
  return (
    <div className='dashboard_topbar'>
      <Menu setIsExpanded={setIsExpanded} expand={expand} />
      <Logo />
      <div className='dashboard_topbar-left-links' />
      <div className='dashboard_topbar-right-links'></div>
    </div>
  );
};
