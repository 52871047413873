import './styles.scss';
import { useSelector } from 'react-redux';

export const User = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      {user ? (
        <div className='dashboard__sidebar-user'>
          <div className='dashboard__sidebar-user-icon'>
            <div className='dashboard__sidebar-user-icon-inner'>
              <div className='wellcome__avatar'>
                {user.avatar ? (
                  <img src={user.avatar} alt='User profile' className='img-fluid rounded-circle' />
                ) : (
                  <span>
                    {user.name.slice(0, 1)}
                    {user.lastname.slice(0, 1)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='dashboard__sidebar-user-text'>
            {user.name} {user.lastname}
          </div>
        </div>
      ) : null}
    </>
  );
};
