import axios from './axios.service';

export const addRegister = (register) => {
  return axios.post('/user/create_register', register);
};

export const updateRegister = (id, register) => {
  return axios.patch(`/user/update_register/${id}`, register);
};

export const getRegisters = () => {
  return axios.get('/user/get_registers').then((res) => {
    return [...res.registers[0], ...res.registers[1]]
  });
};

export const deleteRegister = (id, categoryId) => {
  return axios.delete(`/user/delete_register/${id}?categoryId=${categoryId}`);
};
