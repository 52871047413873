import { actions } from '../actions/actions';

const initialState = [];

export const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SET_EVENTS:
      return payload;

    default:
      return state;
  }
};
