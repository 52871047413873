import React, { useState } from 'react';
import { Pagination } from '../../../../../components';
import { SearchBar } from '../../SearchBar';
import { UserData } from './UserData';

export function ReportsUsersList({ handleSearchUser, data }) {
  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(10);

  return (
    <>
      <SearchBar handleSearchUser={handleSearchUser} />

      <div className='reports_user-list'>
        <div className='reports_user-list-grid'>
          <table className='table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Usuario</th>
                <th>Empresa</th>
                <th>Última Conexión</th>
                <th>Curso</th>
                <th>Avance</th>
                <th>Calificación</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map(
                  (userReport, index) =>
                    index >= initial &&
                    index <= end && (
                      <UserData key={userReport?.user?.id} userReport={userReport} index={index} />
                    )
                )
              ) : (
                <tr>
                  <td colSpan='7' align='center'>
                    No hay usuarios disponibles.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={data?.length} />
      </div>
    </>
  );
}
