import { addUsers, addUser, deleteUser, getUsers, updateUser } from '../../services';
import { actions } from './actions';

export const setUsers = (users) => ({ type: actions.SET_USERS, payload: users });

export const getAllUsers = () => {
  return function (dispatch) {
    return getUsers().then((users) => dispatch(setUsers(users)));
  };
};

export const createUsers = (data) => {
  return function (dispatch) {
    return addUsers(data).then(() => dispatch(getAllUsers()));
  };
};

export const createUser = (user) => {
  return function (dispatch) {
    return addUser(user).then(() => dispatch(getAllUsers()));
  };
};

export const putUser = (id, user) => {
  return function (dispatch) {
    return updateUser(id, user).then(() => dispatch(getAllUsers()));
  };
};

export const removeUser = (id) => {
  return function (dispatch) {
    return deleteUser(id).then(() => dispatch(getAllUsers()));
  };
};
