import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useCourseForm } from "../../hooks/useCourseForm";
import { CATEGORIES, ROLES } from "../../../../utils";
import { Module } from "../editModule";
import { StepperPagination } from "../../../../components/pagination/StepperPagination";
import { Clase } from "../editClase";
import "./styles.scss";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import { CKEditor } from '@ckeditor/ckeditor5-react'

export const AddEdit = ({ courseIdModal, courseSelected, handleSelectCourse }) => {
  const {
    courseSchema,
    initialValues,
    newModule,
    categories,
    hasDiscount,
    handleSubmit,
    renderModuleArrayErrors,
    handleHasDiscount,
    handleCancel,
    users,
    step,
    thumb,
    handlePreviousStep,
    handleNextStep,
    handleChangeStep,
    handleChangeThumb,
  } = useCourseForm({ courseIdModal, courseSelected, handleSelectCourse });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={courseSchema}
      onSubmit={(values, { resetForm }) => handleSubmit({ data: values, thumb }, { resetForm })}
    >
      {({ values, errors, setFieldValue, resetForm }) => {
        return (
          <Form className="course__form" style={{ minHeight: 700 }}>
            <StepperPagination
              step={step}
              totalSteps={values.modules ? values.modules.length + 2 : 2}
              handlePrevious={handlePreviousStep(1)}
              handleNext={handleNextStep(values.modules ? values.modules.length + 2 : 2)}
              handleChangeStep={handleChangeStep(1, values.modules ? values.modules.length + 2 : 2)}
            />

            {step === 1 && (
              <div className="tab-container">
                <h4 className="tab-title">
                  {step} <i className="fas fa-long-arrow-alt-right"></i> Detalles del curso
                </h4>
                <div className="form-group">
                  <label className="course__form-label" htmlFor="course__form-title">
                    <i className="uil uil-edit-alt"></i> Nombre del Curso
                  </label>
                  <Field
                    id="course__form-title"
                    className="course__form-title form-control"
                    name="title"
                    type="text"
                  />
                  <ErrorMessage name="title" component="small" className="form-error-message" />
                </div>

                <div className="form-group">
                  <label className="course__form-label" htmlFor="course__form-description">
                    <i className="uil uil-edit-alt"></i> Descripción Breve
                  </label>
                  <Field
                    as="textarea"
                    id="course__form-description"
                    className="course__form-description form-control"
                    name="description"
                    type="text"
                    rows="3"
                  />
                  <ErrorMessage
                    name="description"
                    component="small"
                    className="form-error-message"
                  />
                </div>

                <div className="form-group">
                  <label className="course__form-label" htmlFor="course__form-description">
                    <i className="uil uil-edit-alt"></i> Descripción Extendida
                  </label>
                  <Field
                    as="textarea"
                    id="course__form-body"
                    className="course__form-description form-control"
                    name="body"
                    type="text"
                    rows="3"
                  />
                  <ErrorMessage name="body" component="small" className="form-error-message" />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label className="course__form-label" htmlFor="course__form-categories">
                      <i className="uil uil-tag-alt"></i> Categoría
                    </label>
                    <Field
                      as="select"
                      id="course__form-category"
                      className="course__form-category form-control"
                      name="categoryId"
                    >
                      <option>Seleccione una categoría</option>
                      {categories?.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="categoryId"
                      component="small"
                      className="form-error-message"
                    />
                  </div>

                  <div className="form-group col-md-5">
                    <label className="course__form-label" htmlFor="course__form-price">
                      <i className="uil uil-dollar-alt"></i> Precio
                    </label>
                    <Field
                      id="course__form-price"
                      className="course__form-price form-control"
                      name="price"
                      type="number"
                      min={0}
                    />
                    <ErrorMessage name="price" component="small" className="form-error-message" />
                  </div>
                </div>

                {Number(values.categoryId) === CATEGORIES.secretariaTrabajo && (
                  <div className="form-group">
                    <label className="course__form-label" htmlFor="course__form-instructor">
                      <i className="uil uil-edit-alt"></i> Instructor
                    </label>
                    <Field
                      as="select"
                      id="course__form-instructor"
                      className="course__form-description form-control"
                      name="instructor_id"
                      type="text"
                      rows="3"
                    >
                      <option value="">Seleccione un instructor</option>
                      {users
                        ?.filter((user) => user.roleId === ROLES.instructor)
                        ?.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name} {user.lastname}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="instructor_id"
                      component="small"
                      className="form-error-message"
                    />
                  </div>
                )}

                <div className="form-check form-group">
                  <input
                    id="course__form-discount-check"
                    className="form-check-input"
                    type="checkbox"
                    checked={hasDiscount}
                    onChange={(e) => handleHasDiscount(e, setFieldValue)}
                  />
                  <label
                    className="course__form-label form-check-label"
                    htmlFor="course__form-discount-check"
                  >
                    Descuento
                  </label>
                </div>

                {hasDiscount && (
                  <div className="form-group">
                    <label className="course__form-label" htmlFor="course__form-discount">
                      <i className="uil uil-percentage"></i> Descuento
                    </label>
                    <Field
                      id="course__form-discount"
                      className="course__form-discount form-control"
                      name="discount"
                      type="number"
                      min={0}
                      max={100}
                    />
                    <ErrorMessage
                      name="discount"
                      component="small"
                      className="form-error-message"
                    />
                  </div>
                )}

                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label className="course__form-label" htmlFor="course__form-duration">
                      <i className="fa-regular fa-clock"></i> Duración
                    </label>
                    <Field
                      id="course__form-duration"
                      className="course__form-duration form-control"
                      name="duration_course"
                      type="number"
                      min={0}
                    />
                    <ErrorMessage
                      name="duration_course"
                      component="small"
                      className="form-error-message"
                    />
                  </div>

                  <div className="form-group col-sm-12 col-md-6">
                    <label className="course__form-label">
                      <i className="uil uil-image-plus"></i> Imagen del curso
                    </label>
                    <label className="course__form-thumb" htmlFor="course__form-thumb">
                      {values.thumb ? values.thumb : !thumb ? "Seleccionar imagen" : thumb.name}
                    </label>
                    <input
                      id="course__form-thumb"
                      type="file"
                      name="thumb"
                      multiple={false}
                      accept="image/png, image/jpeg, image/webp, image/jpg"
                      onChange={handleChangeThumb(setFieldValue)}
                    />
                    <ErrorMessage name="thumb" component="small" className="form-error-message" />
                  </div>

                  <div className="form-group col-sm-12">
                    <label className="course__form-label">
                      <i className="fa-solid fa-list"></i> Lo que aprenderás
                    </label>
                    <FieldArray
                      name="you_learn"
                      render={(arrayHelpers) => (
                        <>
                          {values?.you_learn && values?.you_learn.length > 0 ? (
                            <div className="list_field-content">
                              {values.you_learn.map((element, index) => (
                                <div key={index}>
                                  <div className="list_field-item">
                                    <label className="list_field-label">{index + 1}.</label>
                                    <Field
                                      className="list_field-input"
                                      name={`you_learn[${index}].name`}
                                      autoComplete="false"
                                      type="text"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <i className="uil uil-trash-alt" />
                                    </button>
                                  </div>
                                  <ErrorMessage
                                    name={`you_learn[${index}].name`}
                                    component="small"
                                    className="form-error-message"
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <small>Aún no hay especificaciones.</small>
                            </div>
                          )}

                          <div className="button-add-form list_field-add">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => arrayHelpers.push({ name: "" })}
                            >
                              <i className="fa-solid fa-plus"></i> Agregar item
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <label className="course__form-label">
                      <i className="fa-solid fa-question"></i> ¿Para quién es este curso?
                    </label>
                    <FieldArray
                      name="addressed_to"
                      render={(arrayHelpers) => (
                        <>
                          {values?.addressed_to && values?.addressed_to.length > 0 ? (
                            <div className="list_field-content">
                              {values.addressed_to.map((element, index) => (
                                <div key={index}>
                                  <div className="list_field-item">
                                    <label className="list_field-label">{index + 1}.</label>
                                    <Field
                                      className="list_field-input"
                                      name={`addressed_to[${index}].name`}
                                      autoComplete="false"
                                      type="text"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <i className="uil uil-trash-alt" />
                                    </button>
                                  </div>
                                  <ErrorMessage
                                    name={`addressed_to[${index}].name`}
                                    component="small"
                                    className="form-error-message"
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <small>Aún no hay especificaciones.</small>
                            </div>
                          )}

                          <div className="button-add-form list_field-add">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => arrayHelpers.push({ name: "" })}
                            >
                              <i className="fa-solid fa-plus"></i> Agregar item
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <label className="course__form-label">
                      <i className="fa-solid fa-list-check"></i> Requisitos del curso
                    </label>
                    <FieldArray
                      name="requirements"
                      render={(arrayHelpers) => (
                        <>
                          {values?.requirements && values?.requirements.length > 0 ? (
                            <div className="list_field-content">
                              {values.requirements.map((element, index) => (
                                <div key={index}>
                                  <div className="list_field-item">
                                    <label className="list_field-label">{index + 1}.</label>
                                    <Field
                                      className="list_field-input"
                                      name={`requirements[${index}].name`}
                                      autoComplete="false"
                                      type="text"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <i className="uil uil-trash-alt" />
                                    </button>
                                  </div>
                                  <ErrorMessage
                                    name={`requirements[${index}].name`}
                                    component="small"
                                    className="form-error-message"
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <small>Aún no hay especificaciones.</small>
                            </div>
                          )}

                          <div className="button-add-form list_field-add">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => arrayHelpers.push({ name: "" })}
                            >
                              <i className="fa-solid fa-plus"></i> Agregar item
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="tab-container">
                <h4 className="tab-title">
                  {step} <i className="fas fa-long-arrow-alt-right"></i> Asignar módulos
                </h4>
                <FieldArray
                  name="modules"
                  render={(arrayHelpersModule) => (
                    <>
                      {values?.modules && values?.modules?.length > 0
                        ? values?.modules?.map((element, index) => (
                            <Module
                              key={index}
                              data={element}
                              index={index}
                              errors={errors}
                              arrayHelpersModule={arrayHelpersModule}
                            />
                          ))
                        : null}

                      {renderModuleArrayErrors(errors)}
                      <div className="button-add-form">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => arrayHelpersModule.push(newModule)}
                        >
                          <i className="uil uil-folder-plus"></i> Agregar Modulo
                        </button>
                      </div>
                    </>
                  )}
                />
              </div>
            )}
            {/* {console.log(values)} */}

            {/* {values.modules &&
              values.modules.length > 0 &&
              values.modules.map(
                (modulo, index) =>
                  step === index + 3 && (
                    <div className="tab-container">
                      <h4 className="tab-title">
                        {step} <i className="fas fa-long-arrow-alt-right"></i> Módulo:{" "}
                        {modulo.title ? modulo.title : index + 1}
                      </h4>
                    </div>
                  )
              )} */}

            {step > 2 &&
              values.modules &&
              values.modules.length > 0 &&
              values.modules[step - 3] && (
                <div className="tab-container">
                  <h4 className="tab-title">
                    {step} <i className="fas fa-long-arrow-alt-right"></i> Módulo:{" "}
                    {values.modules[step - 3].title ? values.modules[step - 3].title : step - 2}
                  </h4>
                  <Clase
                    index={step - 3}
                    classes={values.modules[step - 3].classes}
                    errors={errors}
                  />
                </div>
              )}

            <div className="form-actions">
              <div className="form-actions-submit">
                <button type="submit" className="btn btn-dark course__form-submit mt-3 mr-3">
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-light course__form-submit mt-3"
                  onClick={() => handleCancel(resetForm)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
