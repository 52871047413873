export const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem('PROCESO_SEGURO_ADMINISTRATOR_USER_APP');

  const user = JSON.parse(userString);

  if (!user) return null;

  return user.user;
};

export const getUserToken = () => {
  const userString = localStorage.getItem('PROCESO_SEGURO_ADMINISTRATOR_USER_APP');

  const user = JSON.parse(userString);

  if (!user) return null;

  return user.token;
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('PROCESO_SEGURO_ADMINISTRATOR_USER_APP');
};
