import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setMessage } from '../../../store/actions';
import useCategories from '../../../hooks/useCategories';
import { useLocation } from '../../../hooks/useLocation';
import { useRegisters } from '../../../hooks/useRegisters';

const initialValues = {
  instructorId: '',
  categoryId: '',
  estatal: false,
  stateId: '',
  townId: '',
  register: '',
};

export function useRegisterForm({ registerIdModal, selectedRegister, handleSelectRegister }) {
  const { handleSelectedState, states, towns } = useLocation({
    idState: selectedRegister?.stateId,
  });
  const { addRegister, updateRegister } = useRegisters();
  const { categories } = useCategories();
  const [registerData, setRegisterData] = useState(initialValues);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const registerDataCopy = { ...registerData };

    registerDataCopy.instructorId = Number(registerDataCopy.instructorId);
    registerDataCopy.categoryId = Number(registerDataCopy.categoryId);

    if (registerDataCopy.stateId === '') {
      registerDataCopy.stateId = undefined;
    } else {
      registerDataCopy.stateId = Number(registerDataCopy.stateId);
    }
    if (registerDataCopy.townId === '') {
      registerDataCopy.townId = undefined;
    } else {
      registerDataCopy.townId = Number(registerDataCopy.townId);
    }

    let res = null;

    if (selectedRegister) {
      res = updateRegister(registerData.id, registerDataCopy);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Registro actualizado exitosamente',
            error: 'Ha ocurido un error',
          },
          promise: res,
        })
      );
    } else {
      res = addRegister(registerDataCopy);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Registro creado exitosamente',
            error: 'Ha ocurido un error',
          },
          promise: res,
        })
      );
    }

    res
      .then(() => {
        if (selectedRegister) {
          handleSelectRegister(null);
        } else {
          setRegisterData(initialValues);
        }
        window.bootstrap.Modal.getInstance(document.getElementById(registerIdModal)).hide();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleOnChange = (e) => {
    const newRegisterData = { ...registerData };
    if (e.target.name === 'categoryId' && e.target.value !== newRegisterData.categoryId) {
      newRegisterData.estatal = initialValues.estatal;
      newRegisterData.stateId = initialValues.stateId;
      newRegisterData.townId = initialValues.townId;
    }
    if (e.target.name === 'stateId' && e.target.value) {
      handleSelectedState(e.target.value);
    }
    setRegisterData({ ...newRegisterData, [e.target.name]: e.target.value });
  };

  const handleOnChecked = (e) => {
    const newRegisterData = { ...registerData };
    if (e.target.name === 'estatal' && e.target.value !== newRegisterData.estatal) {
      newRegisterData.townId = initialValues.townId;
    }
    setRegisterData({ ...newRegisterData, [e.target.name]: e.target.checked });
  };

  const handleCancell = () => handleSelectRegister(null);

  useEffect(() => {
    if (selectedRegister) {
      setRegisterData(selectedRegister);
    } else {
      setRegisterData(initialValues);
    }
  }, [selectedRegister]);

  return {
    initialValues,
    states,
    towns,
    categories,
    registerData,
    handleSubmit,
    handleOnChange,
    handleOnChecked,
    handleCancell,
  };
}
