import { EXPANDED } from '../constants/app.constants';
import { actions } from './actions';

export const setLoading = (loading) => ({ type: actions.SET_LOADING, payload: loading });

export const expanded = () => (dispatch) => {
  dispatch({
    type: EXPANDED,
  });
};

export const setMessage = (message) => ({ type: actions.SET_MESSAGE, payload: message });

export const showInfoModal = (config) => (dispatch) => {};

export const setInfoModal = (config) => (dispatch) => {};

export const removeInfoModal = () => (dispach) => {};
