import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useRoles } from '../../../hooks/useRoles';
import { useUsers } from '../../../hooks/useUsers';
import { setMessage } from '../../../store/actions';
import { ROLES } from '../../../utils';

const initialState = {
  name: '',
  lastname: '',
  username: '',
  avatar: '',
  email: '',
  password: '',
  roleId: '',
  instructorSignature: '',
};

export default function useUserForm({ userIdModal, userSelected, handleSelectUser }) {
  const userFormSchema = yup.object().shape({
    name: yup.string().required('Este campo es requerido.'),
    lastname: yup.string().required('Este campo es requerido.'),
    username: yup.string().required('Este campo es requerido.'),
    email: yup.string().required('Este campo es requerido.').email('Debe ser un correo válido.'),
    password: yup.string().required('Este campo es requerido.'),
    roleId: yup.string().required('Este campo es requerido.'),
    instructorSignature: yup.string().when('roleId', {
      is: String(ROLES.instructor),
      then: (schema) => schema.required('Este campo es requerido.'),
    }),
  });

  if (userSelected) {
    userSelected.password = 'password';
    userSelected.instructorSignature = userSelected?.signatureInstructors?.[0]?.signature || '';
  }

  if (userSelected && !userSelected?.username) userSelected.username = '';
  const initialValues = userSelected || initialState;
  const { roles } = useRoles();
  const { addUser, updateUser } = useUsers();

  const dispatch = useDispatch();

  const handleSubmit = (data, { setFieldError, resetForm }) => {
    let res = null;

    if (userSelected) {
      const copyData = { ...data };
      copyData.password = undefined;

      res = updateUser(userSelected.id, copyData);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Usuario actualizado exitosamente',
            error: 'Ha ocurrido un error',
          },
          promise: res,
        })
      );
    } else {
      res = addUser(data);
      dispatch(
        setMessage({
          type: 'promise',
          message: {
            success: 'Usuario creado exitosamente',
            error: 'Ha ocurrido un error',
          },
          promise: res,
        })
      );
    }

    res
      .then(() => {
        if (userSelected) {
          handleSelectUser(null);
        } else {
          resetForm();
        }
        window.bootstrap.Modal.getInstance(document.getElementById(userIdModal)).hide();
      })
      .catch((err) => {
        console.error(err.message);

        if (err.response.status === 401) {
          return;
        } else if (err.response?.data?.errors?.[0].msg === 'username is already registered!') {
          setFieldError('username', 'Este nombre de usuario ya ha sido tomado.');
        } else if (err.response?.data?.errors?.[0].msg === 'email is already registered!') {
          setFieldError('email', 'Ya existe un usuario registrado con este correo.');
        }
      });
  };

  const handleCancel = (resetForm) => {
    if (userSelected) {
      handleSelectUser(null);
    } else {
      resetForm();
    }
  };

  return { userFormSchema, initialValues, roles, handleSubmit, handleCancel };
}
