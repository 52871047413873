import { ErrorMessage, Field, FieldArray } from "formik";

const newClass = {
  title: "",
  description: "",
  video: "",
  slide: "",
};

export const Clase = ({ index, classes, errors }) => {
  const renderClassArrayErrors = (errors) =>
    typeof errors?.modules?.[index]?.classes === "string" && (
      <small className="form-error-message">{errors.modules[index].classes}</small>
    );

  return (
    <FieldArray
      name={`modules[${index}].classes`}
      render={(arrayHelpersClass) => (
        <>
          {renderClassArrayErrors(errors)}
          {classes && classes.length > 0
            ? classes.map((classe, idx) => (
                <div key={idx} className="class-content">
                  {idx > 0 && <hr />}

                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => arrayHelpersClass.insert(idx, newClass)}
                  >
                    <i className="uil uil-plus-square"></i> Agregar Clase
                  </button>

                  <div className="form-group">
                    <label className="course__form-label" htmlFor="course__form-title">
                      <i className="uil uil-edit-alt"></i> Titulo de la Clase
                      <button
                        type="button"
                        onClick={() => arrayHelpersClass.remove(idx)}
                        className="btn btn-link course__form-collapse-btn"
                      >
                        <i className="uil uil-trash-alt" style={{ color: "red" }}></i>
                      </button>
                    </label>
                    <Field
                      className="form-control"
                      type="text"
                      name={`modules[${index}].classes[${idx}].title`}
                    />
                    <ErrorMessage
                      name={`modules[${index}].classes[${idx}].title`}
                      component="small"
                      className="form-error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label className="course__form-label" htmlFor="course__form-title">
                      <i className="uil uil-edit-alt"></i> Descripción
                    </label>
                    <Field
                      as="textarea"
                      className="form-control"
                      type="text"
                      name={`modules[${index}].classes[${idx}].description`}
                    />
                    <ErrorMessage
                      name={`modules[${index}].classes[${idx}].description`}
                      component="small"
                      className="form-error-message"
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="course__form-label" htmlFor="course__form-title">
                        <i className="uil uil-video"></i> Enlace Juego
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        name={`modules[${index}].classes[${idx}].video`}
                      />
                      <ErrorMessage
                        name={`modules[${index}].classes[${idx}].video`}
                        component="small"
                        className="form-error-message"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="course__form-label" htmlFor="course__form-title">
                        <i className="uil uil-book-open"></i> Enlace Presentación
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        name={`modules[${index}].classes[${idx}].slide`}
                      />
                      <ErrorMessage
                        name={`modules[${index}].classes[${idx}].slide`}
                        component="small"
                        className="form-error-message"
                      />
                    </div>
                  </div>
                </div>
              ))
            : null}

          <div className="button-add-form btn-right">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => arrayHelpersClass.push(newClass)}
            >
              <i className="uil uil-plus-square"></i> Agregar Clase
            </button>
          </div>
        </>
      )}
    />
  );
};
