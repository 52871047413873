import { Link } from 'react-router-dom';
import logoWhite2 from '../../assets/img/logo-transparent.png';
import bannerImg from '../../assets/img/background/bg1.jpg';
import './styles.scss';

export function NotFound() {
  return (
    <div className='account-form'>
      <div className='account-head' style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
        <Link to='/'>
          <img src={logoWhite2} alt='App-logo' style={{ width: '80%' }} />
        </Link>
      </div>
      <div className='account-form-inner'>
        <div className='account-container'>
          <div className='error-page'>
            <h2 className='error-title'>404</h2>
            <h4 className='m-b15'>No se pudo encontrar la página que estabas buscando.</h4>
            <p className='m-b30'>
              Es posible que la página que está buscando se haya eliminado, que se haya cambiado su
              nombre o que no esté disponible temporalmente.
            </p>
            <div>
              <Link to='/' className='btn outline black'>
                Regresar al Inicio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
