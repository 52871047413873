export const actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_LOADING: 'SET_LOADING',
  SET_COURSES: 'SET_COURSES',
  SET_EXAMS: 'SET_EXAMS',
  SET_EVENTS: 'SET_EVENTS',
  SET_USERS: 'SET_USERS',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_REGISTERS: 'SET_REGISTERS',
  SET_CERTIFICATES: 'SET_CERTIFICATES',
  SET_REPORTS: 'SET_REPORTS',
  SET_COURSES_REPORT: 'SET_COURSES_REPORT',
};
