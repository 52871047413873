import { MainLayout, Modal } from '../../layouts';
import { ExcelCertificate } from './modules/excelCertificate';
import { CertificatesHeader } from './modules/header';
import { CertificateList } from './modules/list/index';
import './styles.scss';

export const Certificates = () => {
  const certificatesIdModal = 'certificates-modal';

  return (
    <MainLayout>
      <CertificatesHeader certificatesIdModal={certificatesIdModal} />

      <div className='certificates grid'>
        <div className='certificates__container'>
          <CertificateList />
        </div>
      </div>

      <Modal id={certificatesIdModal} title='Registrar Usuarios'>
        <ExcelCertificate certificatesIdModal={certificatesIdModal} />
      </Modal>
    </MainLayout>
  );
};
