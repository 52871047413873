import React from 'react';
import { useSelector } from 'react-redux';
import { CATEGORIES, ROLES } from '../../../../utils';
import { useRegisterForm } from '../../hooks/useRegisterForm';
import './styles.scss';

export function AddEdit({ registerIdModal, selectedRegister, handleSelectRegister }) {
  const { users } = useSelector((state) => state);

  const {
    initialValues,
    states,
    towns,
    categories,
    registerData,
    handleSubmit,
    handleOnChange,
    handleOnChecked,
    handleCancell,
  } = useRegisterForm({ registerIdModal, selectedRegister, handleSelectRegister });

  return (
    <form className='register-form' onSubmit={handleSubmit}>
      <div className='form-group register-instructor'>
        <label className='register__form-label'>
          <i className='uil uil-user-md'></i> Instructor
        </label>
        <select
          className='form-select'
          name='instructorId'
          value={registerData.instructorId}
          onChange={handleOnChange}
        >
          <option value=''>Seleccione un instructor</option>
          {users
            .filter((user) => Number(user.roleId) === ROLES.instructor)
            .map((user) => (
              <option key={user.id} value={user.id}>
                {user.name} {user.lastname}
              </option>
            ))}
        </select>
      </div>

      <div className='form-group register-category'>
        <label className='register__form-label'>
          <i className='fa-solid fa-list'></i> Categoría
        </label>
        <select
          className='form-select'
          name='categoryId'
          value={registerData.categoryId}
          onChange={handleOnChange}
        >
          <option value=''>Seleccione una categoría</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      {Number(registerData.categoryId) === CATEGORIES.proteccionCivil && (
        <div className='form-check form-group'>
          <input
            id='estatal'
            className='form-check-input'
            type='checkbox'
            name='estatal'
            checked={registerData.estatal}
            onChange={handleOnChecked}
          />
          <label className='form-check-label' htmlFor='estatal'>
            Estatal
          </label>
        </div>
      )}

      {Number(registerData.categoryId) === CATEGORIES.proteccionCivil && (
        <div className='form-group register-state'>
          <label className='register__form-label'>Estado</label>
          <select
            className='form-select'
            name='stateId'
            value={registerData.stateId || ''}
            onChange={handleOnChange}
          >
            <option value=''>Seleccione un Estado</option>
            {states.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {!registerData.estatal && registerData.stateId && (
        <div className='form-group register-town'>
          <label className='register__form-label'>Municipio</label>
          <select
            className='form-select'
            name='townId'
            value={registerData.townId || ''}
            onChange={handleOnChange}
          >
            <option value=''>Seleccione un Municipio</option>
            {towns.map((town) => (
              <option key={town.id} value={town.id || ''}>
                {town.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className='form-group'>
        <label htmlFor='register__id' className='register__form-label'>
          <i className='fa-solid fa-address-card'></i> Número de registro
        </label>
        <input
          type='text'
          id='register__id'
          className='form-control'
          name='register'
          value={registerData.register || initialValues.register}
          onChange={handleOnChange}
        />
      </div>

      <button type='submit' className='btn btn-dark mt-3 mr-3'>
        Guardar
      </button>
      <button type='button' className='btn btn-light mt-3' onClick={handleCancell}>
        Cancelar
      </button>
    </form>
  );
}
