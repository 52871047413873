import {
  addCourse,
  updateCourse,
  getCourses,
  deleteCourse,
  attachDocuments,
  deleteDocument,
} from "../../services";
import { actions } from "./actions";

export const setCourses = (courses) => ({ type: actions.SET_COURSES, payload: courses });

export const createCourse = (course) => {
  return function (dispatch) {
    return addCourse(course).then(() => dispatch(getAllCourses()));
  };
};

export const attachDocumentsToCourse = (documents) => {
  return function (dispatch) {
    return attachDocuments(documents).then(() => dispatch(getAllCourses()));
  };
};

export const removeDocument = (document_id) => {
  return function (dispatch) {
    return deleteDocument(document_id).then(() => dispatch(getAllCourses()));
  };
};

export const putCourse = (id, course) => {
  return function (dispatch) {
    return updateCourse(id, course).then(() => dispatch(getAllCourses()));
  };
};

export const getAllCourses = () => {
  return function (dispatch) {
    return getCourses().then((courses) => dispatch(setCourses(courses)));
  };
};

export const removeCourse = (id) => {
  return function (dispatch) {
    return deleteCourse(id).then(() => dispatch(getAllCourses()));
  };
};
