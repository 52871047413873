import { useState } from 'react';
import { AddEdit, ExamList, ExamsHeader } from './modules';
import { MainLayout, Modal } from '../../layouts';
import './styles.scss';

export const Exams = () => {
  const examIdModal = 'exam-modal';
  const [examSelected, setExamSelected] = useState(null);

  const handleSelectExam = (exam) => setExamSelected(exam);

  return (
    <MainLayout>
      <ExamsHeader examIdModal={examIdModal} handleSelectExam={handleSelectExam} />

      <ExamList examIdModal={examIdModal} handleSelectExam={handleSelectExam} />

      <Modal id={examIdModal} title={examSelected ? 'Editar Examen' : 'Agregar Examen'}>
        <AddEdit
          examIdModal={examIdModal}
          examSelected={examSelected}
          handleSelectExam={handleSelectExam}
        />
      </Modal>
    </MainLayout>
  );
};
