import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getUserFromLocalStorage, history } from "../utils";

import { fetchInitalData, login } from "../store/actions";

import * as components from "../components";

import PrivateRoute from "./PrivateRoute";

import { useApp } from "../hooks/useApp";

import * as views from "../views";

export const AppRouter = () => {
  const { isLoading } = useApp();

  const dispatch = useDispatch();

  useEffect(() => {
    const user = getUserFromLocalStorage();

    if (!user) return;

    dispatch(login(user));

    dispatch(fetchInitalData());
  }, [dispatch]);

  return isLoading ? (
    <components.Loading />
  ) : (
    <Router history={history}>
      <components.Message />
      {isLoading && <components.Loading />}
      {/* {infoModal.type && (
        <components.InfoModal
          type={infoModal.type}
          handleClose={infoModal.handleClose}
          autoClose={infoModal.autoClose}
          showingTime={infoModal.showingTime}
          action={infoModal.action}
          message={infoModal.message}
          title={infoModal.title}
        />
      )} */}
      <Switch>
        <Route exact path="/login" component={views.Login} />
        <Route
          exact
          path="/password-recovery/:token"
          component={views.Recovery}
        />
        {/* <PrivateRoute exact path='/' component={views.Certificates} /> */}
        <PrivateRoute exact path="/" component={views.Courses} />
        <PrivateRoute exact path="/examenes" component={views.Exams} />
        <PrivateRoute exact path="/eventos" component={views.Events} />
        <PrivateRoute exact path="/usuarios" component={views.Users} />
        <PrivateRoute
          exact
          path="/certificados"
          component={views.Certificates}
        />
        {/* <PrivateRoute exact path='/certPdf' component={views.CertPdf} /> */}
        <PrivateRoute exact path="/registros" component={views.Registers} />
        <PrivateRoute exact path="/reportes" component={views.Reports} />
        <PrivateRoute exact path="/tickets" component={views.Tickets} />
        <Route path="*" component={views.NotFound} />
      </Switch>
    </Router>
  );
};
