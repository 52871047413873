import { createEvent, putEvent, removeEvents } from '../store/actions';
import { useSelector, useDispatch } from 'react-redux';

export const useEvent = () => {
  const { events } = useSelector((state) => state);

  const dispatch = useDispatch();

  const addEvent = (data) => dispatch(createEvent(data));
  const updateEvent = (id, data) => dispatch(putEvent(id, data));
  const deleteEvents = (id) => dispatch(removeEvents(id));

  return { events, addEvent, updateEvent, deleteEvents };
};
