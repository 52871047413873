import React from 'react';

export function CoursesHeader({ courseIdModal, documentsIdModal, handleSelectCourse }) {
  return (
    <div className='courses__header'>
      <h2 className='courses__title'>
        <i className='uil uil-parcel' /> Cursos
      </h2>
      <div className='row'>
        <div className='courses__add'>
        <button
            onClick={() => handleSelectCourse(null)}
            className='btn btn-dark courses__add-btn m-2'
            data-bs-toggle='modal'
            data-bs-target={`#${documentsIdModal}`}
          >
            <i className='uil uil-plus courses__add-icon'></i>{' '}
            <span className='courses__add-text'>Anexar documentos</span>
          </button>
          <button
            onClick={() => handleSelectCourse(null)}
            className='btn btn-dark courses__add-btn m-2'
            data-bs-toggle='modal'
            data-bs-target={`#${courseIdModal}`}
          >
            <i className='uil uil-plus courses__add-icon'></i>{' '}
            <span className='courses__add-text'>Agregar Curso</span>
          </button>
        </div>
      </div>
    </div>
  );
}
