import { useSelector, useDispatch } from 'react-redux';
import { getAllCertificates } from '../store/actions/certificates.actions';

export const useCertificates = () => {
  const certificates = useSelector((state) => state.certificates);

  const dispatch = useDispatch();

  const getCertificates = (data, startDate, endDate) => dispatch(getAllCertificates(data, startDate, endDate));

  return { certificates, getCertificates };
};
