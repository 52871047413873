import React, { useState } from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import { QuestionsOptions } from '../questions-options';
import { Collapse } from '@mui/material';

const option = {
  answer: '',
  isCorrect: false,
};

export const QuizQuestions = ({ data, index, errors, arrayHelpersQuestion }) => {
  const [showAnswers, setShowAnswers] = useState(false);

  const renderquestionOptionArrayErrors = (errors) =>
    typeof errors.quizQuestions?.[index]?.questionOptions === 'string' && (
      <small className='form-error-message'>{errors.quizQuestions[index].questionOptions}</small>
    );
  return (
    <div className='form-group'>
      <div className='form-group'>
        <label className='exam__form-label' htmlFor='exam__form-title'>
          <i className='uil uil-bookmark-full'></i> Pregunta
        </label>
        <button
          type='button'
          onClick={() => arrayHelpersQuestion.remove(index)}
          className='btn btn-link course__form-collapse-btn'
          style={{ position: 'absolute', right: '1rem' }}
        >
          <i className='uil uil-trash-alt' style={{ color: 'red' }}></i>
        </button>
        <button
          type='button'
          onClick={() => setShowAnswers(!showAnswers)}
          className='btn btn-link course__form-collapse-btn'
          data-bs-toggle='collapse'
          aria-expanded={showAnswers}
        >
          {showAnswers ? (
            <i className='uil uil-angle-up'></i>
          ) : (
            <i className='uil uil-angle-down'></i>
          )}
        </button>
        <Field
          as='textarea'
          className='form-control'
          type='text'
          name={`quizQuestions[${index}].question`}
        />
        <ErrorMessage
          name={`quizQuestions[${index}].question`}
          component='small'
          className='form-error-message'
        />
      </div>

      <FieldArray
        name={`quizQuestions[${index}].questionOptions`}
        render={(arrayHelpersOption) => (
          <>
            <Collapse in={showAnswers}>
              {data.questionOptions?.map((option, idx) => (
                <QuestionsOptions
                  key={idx}
                  index={index}
                  idx={idx}
                  arrayHelpersOption={arrayHelpersOption}
                />
              ))}
              <div className='button-add-form btn-right'>
                <div className='btn btn-link' onClick={() => arrayHelpersOption.push(option)}>
                  <i className='uil uil-plus-square'></i> Agregar Opción de Respuesta
                </div>
              </div>
            </Collapse>
            {renderquestionOptionArrayErrors(errors)}
          </>
        )}
      />
    </div>
  );
};
