import './style.scss'

export const Modal = ({ children, id, title = 'Modal title', onClose = () => {} }) => {
  return (
    <div
      className='modal fade'
      id={id}
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              {title}
            </h5>
            <button
              onClick={onClose}
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  );
};
