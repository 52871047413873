import { useEffect, useState } from 'react';
import { getStates, getTowns } from '../services';

export function useLocation({ idState } = {}) {
  const [stateId, setStateId] = useState(null);
  const [states, setStates] = useState([]);
  const [towns, setTowns] = useState([]);

  const handleSelectedState = (stateId) => setStateId(stateId);

  useEffect(() => {
    let isMounted = true;

    getStates()
      .then((states) => {
        if (isMounted) setStates(states);
      })
      .catch((err) => console.error(err.message));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    getTowns(stateId || idState).then((towns) => {
      if (isMounted) setTowns(towns);
    });

    return () => (isMounted = false);
  }, [stateId, idState]);

  return { handleSelectedState, states, towns };
}
