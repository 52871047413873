import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import formPS from '../../assets/img/formPS.jpg';
import { CATEGORIES } from '../../utils';

// Create styles
const styles = StyleSheet.create({
  body: {
    margin: 0,
    paddingBottom: -10,
  },
  imagen: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
  },
  companyLogo: {
    width: '130px',
    maxHeight: '100px',
    objectFit: 'contain',
    position: 'absolute',
    bottom: '86.1%',
    left: '35',
  },
  signature: {
    backgroundColor: 'transparent',
    height: '80px',
    objectFit: 'contain',
    position: 'absolute',
    top: '514.5',
    transform: 'translateX(-50%)',
  },
  text: {
    position: 'absolute',
    width: '70%',
    marginLeft: '45',
    fontSize: '9',
  },
});

// Create Document Component
export const FormDC3 = ({ certificates }) => {
  return (
    <Document>
      {certificates?.map((user) =>
        user.dataRegister?.map(
          (dataRegister) =>
            dataRegister && (
              <Page
                key={`${user?.CURP}-${dataRegister?.course?.id}`}
                size='LETTER'
                style={styles?.body}
              >
                <View wrap={false}>
                  <Image src={formPS} alt='Proceso' style={styles?.imagen} />
                  {user?.company?.company_logo && (
                    <Image
                      source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                      src={user?.company?.company_logo}
                      alt='Logo Empresa'
                      style={styles?.companyLogo}
                    />
                  )}
                  <Text style={[styles?.text, { marginTop: '156' }]}>
                    {user?.Nombres} {user?.Apellidos}
                  </Text>
                  <Text style={[styles?.text, { marginTop: '186' }]}>{user?.CURP}</Text>
                  <Text style={[styles?.text, { marginTop: '186', left: '42%' }]}>
                    {user?.OcupacionEspecifica || 'N.A'}
                  </Text>
                  <Text style={[styles?.text, { marginTop: '220' }]}>{user?.Puesto || 'N.A'}</Text>
                  <Text style={[styles?.text, { marginTop: '272' }]}>
                    {user?.company?.business_name || 'N.A'}
                  </Text>
                  <Text style={[styles?.text, { marginTop: '305' }]}>
                    {user?.company?.federal_taxpayer_registration || 'N.A'}
                  </Text>
                  <Text style={[styles?.text, { width: '85%', marginTop: '350' }]}>
                    {dataRegister?.course?.title}
                  </Text>
                  <Text style={[styles?.text, { marginTop: '389' }]}>
                    {dataRegister?.course?.duration_course} horas
                  </Text>
                  <Text
                    style={[
                      styles?.text,
                      { marginTop: '389', textAlign: 'right', paddingRight: '110' },
                    ]}
                  >
                    {moment(user?.endDate).format('YYYY-MM-DD')}
                  </Text>
                  <Text
                    style={{
                      position: 'absolute',
                      width: '82%',
                      fontSize: '9',
                      marginTop: '389',
                      textAlign: 'right',
                      marginLeft: '3',
                    }}
                  >
                    {moment(user?.endDate).format('YYYY-MM-DD')}
                  </Text>
                  <Text style={[styles?.text, { marginTop: '454' }]}>
                    {Number(dataRegister?.course?.categoryId) === CATEGORIES.proteccionCivil
                      ? 'PPS130829DF8-0013'
                      : dataRegister?.register?.register}
                  </Text>
                  {Number(dataRegister?.course?.categoryId) === CATEGORIES.proteccionCivil
                      ? (
                        <Image
                          source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                          src='https://leptolum.sirv.com/Firmas/firma_don_berrueta_final.jpg'
                          alt='Firma Instructor'
                          style={[styles?.signature, { width: '155px', left: '15.25%' }]}
                        />
                      )
                      : (
                        <Image
                          source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                          src={dataRegister?.register?.instructor?.signatureInstructors?.[0]?.signature}
                          alt='Firma Instructor'
                          style={[styles?.signature, { width: '155px', left: '15.25%' }]}
                        />
                      )}
                  
                  <Text
                    style={[
                      styles?.text,
                      {
                        textAlign: 'center',
                        width: '155px',
                        marginTop: '584',
                      },
                    ]}
                  >
                    {Number(dataRegister?.course?.categoryId) === CATEGORIES.proteccionCivil
                      ? 'Francisco Javier Berrueta González'
                      : `${dataRegister?.register?.instructor?.name} ${dataRegister?.register?.instructor?.lastname}`}
                  </Text>
                  {user?.company?.representLegal?.signature && (
                    <Image
                      source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                      src={user?.company?.representLegal?.signature}
                      alt='Firma Representante Legal'
                      style={[styles?.signature, { width: '170px', left: '42.5%' }]}
                    />
                  )}
                  <Text
                    style={[
                      styles?.text,
                      {
                        textAlign: 'center',
                        width: '155px',
                        left: '28.2%',
                        marginTop: '584',
                      },
                    ]}
                  >
                    {user?.company?.representLegal?.name || 'N.A'}
                  </Text>
                  {user?.company?.representWorker?.signature && (
                    <Image
                      source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                      src={user?.company?.representWorker?.signature}
                      alt='Firma Representante Trabajadores'
                      style={[styles?.signature, { width: '167px', left: '73.5%' }]}
                    />
                  )}
                  <Text
                    style={[
                      styles?.text,
                      {
                        textAlign: 'center',
                        width: '155px',
                        left: '58.6%',
                        marginTop: '584',
                      },
                    ]}
                  >
                    {user?.company?.representWorker?.name || 'N.A'}
                  </Text>
                </View>
              </Page>
            )
        )
      )}
    </Document>
  );
};
