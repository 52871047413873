import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { createExam, putExam, removeExam } from '../store/actions';

export const useExams = () => {
  const { exams } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteExam = (id) =>
    Swal.fire({
      title: '¿Eliminar?',
      text: '¡El examen será eliminado!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeExam(id))
          .then(() => {
            Swal.fire('¡Eliminado!', 'El examen ha sido eliminado exitosamente.', 'success');
          })
          .catch((err) => {
            console.error(err.message);

            if (err.response.status === 401) return;

            Swal.fire('¡Error!', 'Ha ocurrido un error.', 'error');
          });
      }
    });

  const addExam = (exam) => dispatch(createExam(exam));
  const updateExam = (id, exam) => dispatch(putExam(id, exam));

  return { exams, addExam, updateExam, deleteExam };
};
