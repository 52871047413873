export { Login } from "./login";
export { Recovery } from "./recovery";
export { Registers } from "./registers";
export { Certificates } from "./home";
export { Courses } from "./courses";
export { Exams } from "./exams";
export { Events } from "./events";
export { Users } from "./users";
export { CertPdf } from "./certificate/certPdf";
export { Tickets } from "./tickets";
export { FormDC3 } from "./certificate/formDC3";
export { Reports } from "./reports";
export { NotFound } from "./404";
