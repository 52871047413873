import axios from "./axios.service";

import { deserializeList } from "../utils";

export const addCourse = (course) => {
  return axios.post("/course", course, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const attachDocuments = (documents) => {
  return axios.post("/document/lesson", documents, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteDocument = (document_id) => {
  return axios.delete(`/document/${document_id}/lesson`);
};

export const updateCourse = (id, course) => {
  return axios.patch(`/course/${id}`, course, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCourses = () => {
  return axios.get("/course/all").then((res) =>
    res.courses
      ? res.courses.map((course) => ({
          ...course,
          you_learn: deserializeList(course?.you_learn),
          addressed_to: deserializeList(course?.addressed_to),
          requirements: deserializeList(course?.requirements),
        }))
      : []
  );
};

export const getCourseById = (id) => {
  return axios.get(`/course/${id}/full`).then((res) =>
    res.course
      ? {
          ...res.course,
          you_learn: deserializeList(res.course?.you_learn),
          addressed_to: deserializeList(res.course?.addressed_to),
          requirements: deserializeList(res.course?.requirements),
        }
      : {}
  );
};

export const deleteCourse = (id) => {
  return axios.delete(`/course/${id}`);
};
