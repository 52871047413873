import { useState } from 'react';
import { AddEdit, EventList, EventsHeader } from './modules';
import { MainLayout, Modal } from '../../layouts';
import './styles.scss';

export const Events = () => {
  const eventIdModal = 'event-modal';
  const [eventSelected, setEventSelected] = useState(null);

  const handleSelectEvent = (event) => setEventSelected(event);

  return (
    <MainLayout>
      <EventsHeader eventIdModal={eventIdModal} handleSelectEvent={handleSelectEvent} />

      <EventList eventIdModal={eventIdModal} handleSelectEvent={handleSelectEvent} />

      <Modal id={eventIdModal} title={eventSelected ? 'Editar Evento' : 'Agregar Evento'}>
        <AddEdit
          eventIdModal={eventIdModal}
          eventSelected={eventSelected}
          handleSelectEvent={handleSelectEvent}
        />
      </Modal>
    </MainLayout>
  );
};
