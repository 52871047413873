import React, { useState } from 'react';
import { UserCourseData } from './UserCourseData';

export function UserData({ userReport, index }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = () => setIsVisible(() => !isVisible);

  return (
    <>
      <tr key={userReport?.user?.id} className='user-item'>
        <th data-label='#' scope='row' style={{ verticalAlign: 'middle' }}>
          <button onClick={handleVisibility} className='drop-button'>
            {isVisible && <i className='fa-solid fa-caret-up' />}
            {!isVisible && <i className='fa-solid fa-caret-down' />}
          </button>
          {index + 1}
        </th>
        <td data-label='Usuario:'>
          {userReport?.user?.name} {userReport?.user?.last_name}
        </td>
        <td data-label='Empresa:'>{userReport?.user?.company}</td>
        <td data-label='Última Conexión:'>{userReport?.user?.last_login || '-'}</td>
        <td colSpan='3' className='empty-user-fields' />
      </tr>
      {userReport?.courses.map(
        (course) =>
          isVisible && <UserCourseData key={course?.id} user={userReport?.user} course={course} />
      )}
    </>
  );
}
