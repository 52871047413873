import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

const MAX_LENGTH = 5;

export const StepperPagination = ({
  step,
  totalSteps,
  handleChangeStep,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div className="stepper-actions">
      {step > 1 && (
        <button type="button" onClick={handlePrevious} className="step previous-step">
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      )}

      <div className="steps">
        {Array.from({ length: Math.min(totalSteps, MAX_LENGTH) }, (_, index) => {
          if (totalSteps <= MAX_LENGTH) return index + 1;
          if (step <= Math.ceil(MAX_LENGTH / 2)) {
            return index + 1;
          }
          if (step + 2 <= totalSteps) {
            return index + step - 2;
          }
          if (!step + 2 < totalSteps) {
            return index + totalSteps - MAX_LENGTH + 1;
          }
          return index + 1;
        }).map((item, idx) => (
          <>
            <button
              key={item}
              type="button"
              disabled={item === step}
              onClick={() => handleChangeStep(item)}
              className={item === step ? "active" : ""}
            >
              {item}
            </button>
            {idx + 1 < Math.min(totalSteps, MAX_LENGTH) && <div className="separator" />}
          </>
        ))}
      </div>

      {step < totalSteps && (
        <button type="button" onClick={handleNext} className="step next-step">
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      )}
    </div>
  );
};
