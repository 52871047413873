export { addCourse, attachDocuments, deleteDocument, updateCourse, getCourses, getCourseById, deleteCourse } from './course.service';
export { getUsersCoursesReports, getCoursesReports, updateUserCourseAttemps } from './reports.service';
export { getAllTickets, getTicketById, updateTicketStatus, deleteTicketById } from './tickets.service';
export { addRegister, updateRegister, getRegisters, deleteRegister } from './register.service';
export { addEvent, updateEvent, getEvents, getEventById, deleteEvent } from './event.service';
export { signInService, sendEmailRecoverPassword, changeUserPassword } from './auth.service';
export { addExam, updateExam, getExams, getExamById, deleteExam } from './exam.service';
export { getUsers, addUsers, addUser, updateUser, deleteUser } from './user.service';
export { generateCertificates } from './certificate.service';
export { getStates, getTowns } from './location.service';
export { getCategories } from './category.service';
export { getRoles } from './role.service';
