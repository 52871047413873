import React from 'react';
// import './styles.scss';

export function CertificatesHeader({ certificatesIdModal }) {
  return (
    <div className='users__header'>
      <h2 className='users__title mb-0'>
        <i className='uil uil-newspaper' /> Certificados
      </h2>

      <div className='row'>
        <div className='users__add'>
          <button
            className='btn btn-dark users__add-btn'
            data-bs-toggle='modal'
            data-bs-target={`#${certificatesIdModal}`}
          >
            <i className='uil uil-file-bookmark-alt users__add-icon'></i>{' '}
            <span className='users__add-text'>Cargar Excel</span>
          </button>
        </div>
      </div>
    </div>
  );
}
