import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../layouts';
import { ReportsCoursesList, ReportsHeader, ReportsUsersList } from './modules';
import { REPORT_CATEGORY } from '../../utils';
import './styles.scss';

export function Reports() {
  const { usersCoursesReports } = useSelector((state) => state.reports);
  const [reportCategory, setReportCategory] = useState(REPORT_CATEGORY.usuarios);
  const [userSearch, setUserSearch] = useState('');
  const filteredUsers = useMemo(() => {
    const reports = usersCoursesReports.filter((report) => {
      const userName = `${report?.user?.name} ${report?.user?.last_name}`.toLowerCase();
      return userName.includes(userSearch.trim().toLowerCase());
    });

    return reports;
  }, [usersCoursesReports, userSearch]);

  const handleChangeReportCategory = (category) => setReportCategory(category);
  const handleSearchUser = (userName) => setUserSearch(userName);

  return (
    <div>
      <MainLayout>
        <ReportsHeader
          handleChangeReportCategory={handleChangeReportCategory}
          handleSearchUser={handleSearchUser}
        />
        {reportCategory === REPORT_CATEGORY.usuarios && (
          <ReportsUsersList handleSearchUser={handleSearchUser} data={filteredUsers} />
        )}
        {reportCategory === REPORT_CATEGORY.cursos && <ReportsCoursesList />}
      </MainLayout>
    </div>
  );
}
