import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import upload from '../../../../assets/img/background/upload.png';
import fileImg from '../../../../assets/img/background/file.png';
import { useExcelCertificate } from '../../hooks/useExcelCertificate';
import './styles.scss';

export function ExcelCertificate({ certificatesIdModal }) {
  const {
    initialState,
    excelUsersSchema,
    courses,
    excel,
    handleChange,
    handleCancell,
    handleSubmit,
  } = useExcelCertificate({ certificatesIdModal });

  return (
    <Formik
      initialValues={initialState}
      onSubmit={handleSubmit}
      validationSchema={excelUsersSchema}
    >
      {({ setFieldValue, resetForm }) => (
        <Form>
          <div className='excel__register-form'>
            <div className='select-excel'>
              <label>
                <i className='fa-solid fa-book'></i> Seleccione el/los curso/s
              </label>
              <Field as='select' className='form-select ' name='courses' multiple>
                {courses.length > 0 &&
                  courses.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.title}
                    </option>
                  ))}
              </Field>
              <ErrorMessage name='courses' className='form-error-message' component='small' />
            </div>

            <div className='company-log'>
              <label htmlFor='company-logo'>
                <i className='fa-solid fa-building'></i> Logo de la empresa
              </label>
              <Field id='company-logo' type='text' className='form-control' name='companyLogo' />
              <ErrorMessage name='companyLogo' className='form-error-message' component='small' />
            </div>

            <div className='agent-signature'>
              <label htmlFor='agent-signature'>
                <i className='fa-solid fa-signature'></i> Firma del represéntate legal
              </label>
              <Field
                id='agent-signature'
                type='text'
                className='form-control'
                name='agentSignature'
              />
              <ErrorMessage
                name='agentSignature'
                className='form-error-message'
                component='small'
              />
            </div>

            <div className='employees-agent-signature'>
              <label htmlFor='employees-agent-signature'>
                <i className='fa-solid fa-signature'></i> Firma del represéntate de los trabajadores
              </label>
              <Field
                id='employees-agent-signature'
                type='text'
                className='form-control'
                name='employeesAgentSignature'
              />
            </div>

            <div className='start-date'>
              <label htmlFor='start-date'>
                <i className='fa-solid fa-signature'></i> Fecha de inicio
              </label>
              <Field
                id='start-date'
                type='date'
                className='form-control'
                name='startDate'
              />
              <ErrorMessage
                name='startDate'
                className='form-error-message'
                component='small'
              />
            </div>

            <div className='end-date'>
              <label htmlFor='end-date'>
                <i className='fa-solid fa-signature'></i> Fecha de finalización
              </label>
              <Field
                id='end-date'
                type='date'
                className='form-control'
                name='endDate'
              />
              <ErrorMessage
                name='endDate'
                className='form-error-message'
                component='small'
              />
            </div>

            <div>
              <div className='file-excel'>
                <div className='label-input-excel'>
                  <img src={upload} alt='Upload Excel file' />
                  <p>Arrastra o carga el archivo aquí</p>
                </div>
                <Field
                  id='excel-input-file'
                  type='file'
                  accept='.xlsx'
                  name='excel'
                  onChange={(e) => handleChange(e, setFieldValue)}
                />
              </div>
              <ErrorMessage name='excel' className='form-error-message' component='small' />
            </div>

            {excel && (
              <div className='file-info-section'>
                <img src={fileImg} alt='Excel file' />
                <span className='file-name'>{excel.name} </span>
                <span className='file-size'>{excel.size} KB</span>
              </div>
            )}
          </div>

          <button type='submit' className='btn btn-dark user_form-submit mt-3 mr-3'>
            Guardar
          </button>
          <button
            type='button'
            className='btn btn-light user_form-submit mt-3'
            onClick={() => handleCancell(resetForm)}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Formik>
  );
}
