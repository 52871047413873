import axios from "./axios.service";

export const getAllTickets = () => {
  return axios.get("/ticket").then((res) => res.tickets);
};

export const getTicketById = (id) => {
  return axios.get(`/ticket/${id}`).then((res) => res.ticket);
};

export const updateTicketStatus = (id) => {
  return axios
    .patch(`/ticket/${id}`, {
      status: "close",
    })
    .then((res) => res.ticket);
};

export const deleteTicketById = (id) => {
  return axios.delete(`/ticket/${id}`);
};
