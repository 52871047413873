import { useSelector, useDispatch } from 'react-redux';
import { expanded } from '../store/actions/app.actions';

export const useApp = () => {
  const { expand, isLoading, infoModal } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const setIsExpanded = () => dispatch(expanded());

  // console.log(isLoading);
  return {
    expand,
    setIsExpanded,
    isLoading,
    infoModal,
  };
};
