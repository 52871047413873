import { actions } from '../actions/actions';
import { LOGIN, LOGOUT } from '../constants/auth.constants';

const initialState = {
  status: 'logged out',
  user: null,
};

export const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.LOGIN:
      return {
        ...state,
        status: 'logged in',
        user: payload,
      };
    case actions.LOGOUT:
      return {
        ...state,
        status: 'logged out',
        user: null,
      };
    default:
      return state;
  }
};
