import Swal from "sweetalert2";
import Select from "react-select";
import { FieldArray, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { attachDocumentsToCourse, removeDocument } from "../../../../store/actions/courses.actions";
import { StepperPagination } from "../../../../components/pagination/StepperPagination";
import { PdfIcon } from "../../../../components/Icons/PdfIcon";
import { setMessage } from "../../../../store/actions";
import "./styles.scss";

export const AttachDocuments = ({ documentsIdModal }) => {
  const { courses } = useSelector((state) => state);
  const [course, setCourse] = useState(null);
  const [files, setFiles] = useState({});
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const options = useMemo(() => {
    return courses?.map((course, index) => ({ value: index, label: course.title })) || [];
  }, [courses]);

  const handlePreviousStep = (minSteps) => {
    return () => {
      setStep((prevStep) => Math.max(minSteps, prevStep - 1));
    };
  };

  const handleNextStep = (maxSteps) => {
    return () => {
      setStep((prevStep) => Math.min(prevStep + 1, maxSteps));
    };
  };

  const handleChangeStep = (minStep, maxStep) => {
    return (newStep) => {
      if (newStep < minStep) return;
      if (newStep > maxStep) return;
      setStep(newStep);
    };
  };

  const handleAddDocument = (classId) => {
    return (e) => {
      const files = e.target.files;
      const formats = ["application/pdf"];
      const filteredFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (formats.includes(files[i].type)) {
          filteredFiles.push(files[i]);
        }
      }
      setFiles((prevFiles) => ({
        ...prevFiles,
        [classId]: prevFiles[classId] ? [...prevFiles[classId], ...filteredFiles] : filteredFiles,
      }));
    };
  };

  const handleRemoveFile = (classId, index) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [classId]: prevFiles[classId] ? prevFiles[classId].filter((_, idx) => index !== idx) : [],
    }));
  };

  const handleDeleteFile = (fileId, cb, idx) => {
    Swal.fire({
      title: "¿Eliminar?",
      text: "¡El documento será eliminado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeDocument(fileId))
          .then(() => {
            cb(idx);
            Swal.fire("¡Eliminado!", "El documento ha sido eliminado exitosamente.", "success");
          })
          .catch((err) => {
            console.error(err.message);

            if (err.response.status === 401) return;

            Swal.fire("¡Error!", "Ha ocurrido un error.", "error");
          });
      }
    });
  };

  const handleSubmit = (_, { resetForm }) => {
    const data = new FormData();
    const documents = [];
    for (const key in files) {
      for (let i = 0; i < files[key].length; i++) {
        documents.push(files[key][i]);
        data.append(`${documents.length - 1}`, key);
        data.append("documents", files[key][i]);
      }
    }

    const res = dispatch(attachDocumentsToCourse(data));
    dispatch(
      setMessage({
        type: "promise",
        message: {
          success: "Documentos cargados exitosamente",
          error: "Ha ocurido un error",
        },
        promise: res,
      })
    );

    res
      .then(() => {
        window.bootstrap.Modal.getInstance(document.getElementById(documentsIdModal)).hide();
        setCourse(null);
        setStep(1);
        resetForm();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <Formik enableReinitialize initialValues={course || {}} onSubmit={handleSubmit}>
      {({ values, resetForm }) => {
        return (
          <Form className="attach-documents__form">
            {course && (
              <StepperPagination
                step={step}
                totalSteps={values.modules ? values.modules.length + 1 : 1}
                handlePrevious={handlePreviousStep(1)}
                handleNext={handleNextStep(values.modules ? values.modules.length + 2 : 2)}
                handleChangeStep={handleChangeStep(
                  1,
                  values.modules ? values.modules.length + 2 : 2
                )}
              />
            )}

            {step === 1 && (
              <div className="tab-container">
                <h4 className="tab-title">
                  {step} <i className="fas fa-long-arrow-alt-right"></i> Seleccione el curso
                </h4>
                <div className="form-group">
                  <Select
                    placeholder="Cursos..."
                    noOptionsMessage="No hay cursos para listar."
                    value={course ? { value: course.index, label: course.title } : null}
                    options={options}
                    onChange={(e) => {
                      if (course && e.value === course.index) return;
                      setFiles({});
                      setCourse({ ...courses[e.value], index: e.value });
                      setStep((prevStep) => prevStep + 1);
                    }}
                  />
                </div>
              </div>
            )}

            {course && values.modules && values.modules.length > 0 && values.modules[step - 2] && (
              <div className="tab-container">
                <h4 className="tab-title">
                  {step} <i className="fas fa-long-arrow-alt-right"></i> Módulo:{" "}
                  {values.modules[step - 2].title ? values.modules[step - 2].title : step - 1}
                </h4>
                {values.modules[step - 2].classes?.map((clase, idx) => (
                  <div key={clase.id} className="class">
                    <p>
                      <span>Clase: </span>
                      {clase.title}
                    </p>

                    <label htmlFor={`course__form-docs-${clase.id}`} className="course__form-docs">
                      Cargar documentos
                    </label>
                    <input
                      id={`course__form-docs-${clase.id}`}
                      type="file"
                      multiple
                      accept="application/pdf"
                      onChange={handleAddDocument(clase.id)}
                    />
                    <div className="course__docs-wrapper">
                      <FieldArray
                        name={`modules[${step - 2}].classes[${idx}].documents`}
                        render={(arrayHelpers) => (
                          <>
                            {clase?.documents && clase.documents.length > 0 ? (
                              <div className="list_field-content">
                                {clase.documents.map((file, index) => (
                                  <div key={file.id} className="course__docs-item">
                                    <a href={file.url}>
                                      <PdfIcon size={20} /> <span>{file.file_name_document}</span>
                                    </a>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDeleteFile(file.id, arrayHelpers.remove, index)
                                      }
                                    >
                                      <i className="uil uil-trash-alt" />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                <small>Aún no hay documentos.</small>
                              </div>
                            )}
                          </>
                        )}
                      />

                      {files[clase.id]?.map((file, j) => (
                        <div key={j} className="course__docs-item">
                          <div>
                            <PdfIcon size={20} /> <span>{file.name}</span>
                          </div>
                          <button type="button" onClick={() => handleRemoveFile(clase.id, j)}>
                            <i className="uil uil-trash-alt" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="form-actions">
              <div className="form-actions-submit">
                <button type="submit" className="btn btn-dark course__form-submit mt-3 mr-3">
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-light course__form-submit mt-3"
                  onClick={() => {
                    setFiles({});
                    setStep(1);
                    setCourse(null);
                    resetForm();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
