import * as Yup from "yup";

export function useEventForm() {
  const eventSchema = Yup.object().shape({
    title: Yup.string().required("Este campo es requerido."),
    description: Yup.string().required("Este campo es requerido."),
    body: Yup.string().required("Este campo es requerido."),
    // thumb: Yup.string().required('Este campo es requerido.'),
    imparted: Yup.string().required("Este campo es requerido."),
    date: Yup.string().required("Este campo es requerido."),
    hour_start: Yup.string().required("Este campo es requerido."),
    hour_end: Yup.string().required("Este campo es requerido."),
    location: Yup.string().required("Este campo es requerido."),
  });

  return { eventSchema };
}
