import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { createCourse, putCourse, removeCourse } from '../store/actions';

export const useCourses = () => {
  const { courses } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteCourse = (id) =>
    Swal.fire({
      title: '¿Eliminar?',
      text: '¡El curso será eliminado!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeCourse(id))
          .then(() => {
            Swal.fire('¡Eliminado!', 'El curso ha sido eliminado exitosamente.', 'success');
          })
          .catch((err) => {
            console.error(err.message);

            if (err.response.status === 401) return;

            Swal.fire('¡Error!', 'Ha ocurrido un error.', 'error');
          });
      }
    });

  const addCourse = (course) => dispatch(createCourse(course));
  const updateCourse = (id, course) => dispatch(putCourse(id, course));

  return { courses, addCourse, updateCourse, deleteCourse };
};
