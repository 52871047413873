import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { createUsers, createUser, putUser, removeUser } from '../store/actions';

export const useUsers = () => {
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const deleteUser = (id) =>
    Swal.fire({
      title: '¿Eliminar?',
      text: '¡El usuario será eliminado!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeUser(id))
          .then(() => {
            Swal.fire('¡Eliminado!', 'El usuario ha sido eliminado exitosamente.', 'success');
          })
          .catch((err) => {
            console.error(err.message);

            if (err.response.status === 401) return;

            Swal.fire('¡Error!', 'Ha ocurrido un error.', 'error');
          });
      }
    });

  const addUsers = (data) => dispatch(createUsers(data));
  const addUser = (user) => dispatch(createUser(user));
  const updateUser = (id, user) => dispatch(putUser(id, user));

  return { users, addUsers, addUser, updateUser, deleteUser };
};
