import axios from './axios.service';

export const addExam = (data) => {
  return axios.post('/quiz', data);
};

export const updateExam = (id, data) => {
  return axios.put(`/quiz/${id}`, data);
};

export const getExams = () => {
  return axios.get('/quiz/all').then((res) => res.data);
};

export const getExamById = (id) => {
  return axios.get(`/quiz/${id}`).then((res) => res.quiz);
};

export const deleteExam = (id) => {
  return axios.delete(`/quiz/${id}`);
};
