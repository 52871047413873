import { useSelector, useDispatch } from 'react-redux';
import { signIn } from '../store/actions';

export const useAuth = () => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const logIn = (credentials) => dispatch(signIn(credentials));

  return {
    user,
    logIn,
  };
};
