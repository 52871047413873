import { useState } from 'react';
import { UsersHeader, AddEdit, ExcelRegister, UserList } from './modules';
import { MainLayout, Modal } from '../../layouts';
import './styles.scss';

export const Users = () => {
  const usersIdModal = 'users-modal';
  const userIdModal = 'user-modal';
  const [userSelected, setSelectedUser] = useState(null);

  const handleSelectUser = (user) => setSelectedUser(user);

  return (
    <MainLayout>
      <UsersHeader
        usersIdModal={usersIdModal}
        userIdModal={userIdModal}
        handleSelectUser={handleSelectUser}
      />

      <UserList userIdModal={userIdModal} handleSelectUser={handleSelectUser} />

      <Modal id={userIdModal} title={userSelected ? 'Editar Usuario' : 'Agregar Usuario'}>
        <AddEdit
          userIdModal={userIdModal}
          userSelected={userSelected}
          handleSelectUser={handleSelectUser}
        />
      </Modal>

      <Modal id={usersIdModal} title='Registrar Usuarios'>
        <ExcelRegister usersIdModal={usersIdModal} />
      </Modal>
    </MainLayout>
  );
};
