import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useCourses } from '../../../hooks/useCourses';
import { setMessage } from '../../../store/actions';
import { useUsers } from '../../../hooks/useUsers';

const initialState = {
  courses: [],
  excel: '',
  companyLogo: '',
  agentSignature: '',
  employeesAgentSignature: '',
};

export function useExcelUsers({ usersIdModal }) {
  const excelUsersSchema = yup.object().shape({
    courses: yup.array().min(1, 'Seleccione al menos un curso'),
    companyLogo: yup.string().required('Este campo es requerido.'),
    agentSignature: yup.string().required('Este campo es requerido.'),
    excel: yup.string().required('Seleccione un archivo.').matches(/.xlsx/, {
      message: 'El archivo debe ser formato .xlsx(Excel).',
    }),
  });

  const { courses } = useCourses();
  const { addUsers } = useUsers();
  const [excel, setExcel] = useState(null);

  const dispatch = useDispatch();

  const handleChange = (e, setFieldValue) => {
    setFieldValue('excel', e.target.value);
    setExcel(e.target.files[0]);
  };

  const handleCancell = (resetForm) => {
    setExcel(null);
    resetForm();
  };

  const handleSubmit = (data, { resetForm }) => {
    const formData = new FormData();
    formData.append('courses', data.courses);
    formData.append('companyLogo', data.companyLogo);
    formData.append('agentSignature', data.agentSignature);
    formData.append('employeesAgentSignature', data.employeesAgentSignature);
    formData.append('users', excel);

    const res = addUsers(formData)
      .then(() => {
        resetForm();
        setExcel(null);
        window.bootstrap.Modal.getInstance(document.getElementById(usersIdModal)).hide();
      })
      .catch((err) => {
        console.error(err.message);
      });

    dispatch(
      setMessage({
        type: 'promise',
        message: {
          success: 'Registro exitoso',
          error: 'Ha ocurido un error',
        },
        promise: res,
      })
    );

    res
      .then(() => {
        resetForm();
        window.bootstrap.Modal.getInstance(document.getElementById(usersIdModal)).hide();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return {
    initialState,
    excelUsersSchema,
    courses,
    excel,
    handleChange,
    handleCancell,
    handleSubmit,
  };
}
