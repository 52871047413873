import React, { useState } from "react";
import { Link } from "react-router-dom";

import { logo } from "../../../assets/img";

import { changeUserPassword } from "../../../services/auth.service";

import { history } from "../../../utils";

const initialState = {
  new_password: "",
};

export function RecoveryForm() {
  const [data, setData] = useState(initialState);
  const [tokenError, setTokenError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
    if (tokenError) setTokenError("");
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const url = window.location.pathname;
    const token = url.replace("/password-recovery/", "");

    try {
      setIsLoading(true);

      await changeUserPassword({ ...data, token });

      history.replace("/login");

      setIsLoading(false);
    } catch (error) {
      setTokenError("Enlace no valido o expirado.");
    }
  };

  return (
    <form className="login__form" onSubmit={handleOnSubmit}>
      <div className="login__logo">
        <img src={logo} alt="Proceso Seguro" className="login__logo-img" />
      </div>
      <div className="login__input-group">
        <input
          id="new_password"
          name="new_password"
          type="password"
          className="login__input"
          value={data.password}
          onChange={({ target }) => handleOnChange(target)}
          required={true}
        />
        <label htmlFor="new_password" className="login__input-label">
          Nueva Contraseña
        </label>
      </div>

      {tokenError && (
        <div className="login__error">
          <small className="text-danger">{tokenError}</small>
        </div>
      )}

      <div className="login__link-recovery">
        <Link to="/login">Iniciar Sesión</Link>
      </div>

      <div className="login__form-btn">
        <button className="btn btn-dark login-button" disabled={isLoading}>
          Enviar
        </button>
      </div>
    </form>
  );
}
