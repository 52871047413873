import React, { useState } from 'react';
import { getUserFromLocalStorage, history } from '../../utils';
import { EmailForm, LoginForm } from './components';
import './styles.scss';

export const Login = () => {
  const user = getUserFromLocalStorage();
  const [recoverPassword, setRecoverPassword] = useState(false);

  const handleChangeToLogIn = () => setRecoverPassword(false);

  const handleChangeToRecoverPassword = () => setRecoverPassword(true);

  if (user) history.replace('/');

  return (
    <section className='login'>
      <div className='container login__content'>
        {recoverPassword ? (
          <div>
            <EmailForm handleChangeToLogIn={handleChangeToLogIn} />
          </div>
        ) : (
          <LoginForm handleChangeToRecoverPassword={handleChangeToRecoverPassword} />
        )}
      </div>
    </section>
  );
};
