import React, { useMemo, useState } from 'react';
import { CATEGORIES } from '../../utils';
import { MainLayout, Modal } from '../../layouts';
import { useRegisters } from '../../hooks/useRegisters';
import { AddEdit, RegistersHeader, RegistersList } from './modules';
import './styles.scss';

export function Registers() {
  const { registers } = useRegisters();
  const registerIdModal = 'register-modal';
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [category, setCategory] = useState(CATEGORIES.proteccionCivil);
  const [registerSearch, setRegisterSearch] = useState('');
  const registersFiltered = useMemo(
    () =>
      registers.filter(
        (register) =>
          register.category?.id === category &&
          register.register.toLowerCase().includes(registerSearch.trim().toLowerCase())
      ),
    [registers, category, registerSearch]
  );

  const handleChangeCategory = (category) => setCategory(category);
  const handleSelectRegister = (register) => setSelectedRegister(register);
  const handleSearchRegister = (register) => setRegisterSearch(register);

  return (
    <MainLayout>
      <RegistersHeader
        registerIdModal={registerIdModal}
        handleSelectRegister={handleSelectRegister}
        handleSearchRegister={handleSearchRegister}
        handleChangeCategory={handleChangeCategory}
      />
      <RegistersList
        registerIdModal={registerIdModal}
        category={category}
        data={registersFiltered}
        handleSelectRegister={handleSelectRegister}
      />
      <Modal id={registerIdModal} title={selectedRegister ? 'Editar Registro' : 'Agregar Registro'}>
        <AddEdit
          registerIdModal={registerIdModal}
          selectedRegister={selectedRegister}
          handleSelectRegister={handleSelectRegister}
        />
      </Modal>
    </MainLayout>
  );
}
