import { useEffect, useState } from 'react';
import { getCategories } from '../services';

export default function useCategories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isMounted = true;

    getCategories()
      .then((categories) => {
        if (isMounted) setCategories(categories);
      })
      .catch((err) => console.error(err.message));

    return () => (isMounted = false);
  }, []);

  return { categories };
}
