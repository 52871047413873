import axios from './axios.service';

export const addEvent = (event) => {
  // CONFIG OBJECT FOR HEADERS AND ADINIONAL INFORMATION
  return axios.post('/event', event);
};

export const updateEvent = (id, event) => {
  // CONFIG OBJECT FOR HEADERS AND ADINIONAL INFORMATION
  return axios.put(`/event/${id}`, event);
};

export const getEvents = () => {
  return axios.get('/event/all').then((res) => res.events);
};

export const getEventById = (id) => {
  return axios.get(`/event/${id}`).then((res) => res.event);
};

export const deleteEvent = (id) => {
  // CONFIG OBJECT FOR HEADERS AND ADINIONAL INFORMATION
  return axios.delete(`/event/${id}`);
};
