import axios from './axios.service';

export const signInService = (credentials) => {
  return axios.post('/auth/login', credentials).then((res) => ({
    user: res.user,
    token: res.token,
  }));
};

export const sendEmailRecoverPassword = (credentials) => {
  return axios.post('/auth/forgot_password', credentials);
};

export const changeUserPassword = (credentials) => {
  return axios.patch('/auth/new_password', credentials);
};
