import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCategories from "../../../hooks/useCategories";
import { useCourses } from "../../../hooks/useCourses";
import { setMessage } from "../../../store/actions";
import { CATEGORIES, serializeList } from "../../../utils";

const initialState = {
  title: "",
  description: "",
  price: "",
  discount: "",
  thumb: "",
  body: "",
  categoryId: "",
  instructor_id: "",
  duration_course: "",
};

const newModule = {
  title: "",
  quizId: null,
  classes: [],
};

export function useCourseForm({
  courseIdModal,
  courseSelected,
  handleSelectCourse,
}) {
  const courseSchema = Yup.object().shape({
    title: Yup.string().required("Este campo es requerido."),
    description: Yup.string().required("Este campo es requerido."),
    price: Yup.number()
      .min(0, "El precio no puede ser menor a 0")
      .required("Este campo es requerido."),
    discount: Yup.number()
      .min(0, "El descuento no puede ser menor a 0%")
      .max(100, "El descuento no puede ser mayor a 100%"),
    duration_course: Yup.number()
      .min(0, "La duración no puede ser menor a 0 horas.")
      .required("Este campo es requerido."),
    thumb: Yup.string().required("Este campo es requerido."),
    body: Yup.string().required("Este campo es requerido."),
    categoryId: Yup.string().required("Este campo es requerido."),
    instructor_id: Yup.string().when("categoryId", {
      is: (categoryId) => Number(categoryId) === CATEGORIES.secretariaTrabajo,
      then: (schema) => schema.required("Este campo es requerido."),
    }),
    modules: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required("Este campo es requerido."),
          // quizId: Yup.string().required("Este campo es requerido."),
          classes: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required("Este campo es requerido."),
              description: Yup.string().required("Este campo es requerido."),
            })
          ),
          /* .when(['title', 'description', 'video', 'slide'], {
              is: (title, description, video, slide) =>
                title !== '' && description !== '' && video !== '' && slide !== '',
              then: (schema) =>
                schema.required().min(1, 'Es necesario agregar como mínimo una clase.'),
            }), */
        })
      )
      .when(
        ["title", "description", "price", "duration_course", "thumb", "body"],
        {
          is: (title, description, price, duration_course, thumb, body) =>
            title !== "" &&
            description !== "" &&
            price !== "" &&
            duration_course !== "" &&
            thumb !== "" &&
            body !== "",
          then: (schema) =>
            schema.required("Es necesario agregar como mínimo un módulo."),
        }
      ),
  });

  const { users } = useSelector((state) => state);
  const initialValues = courseSelected || initialState;
  const { addCourse, updateCourse } = useCourses();
  const { categories } = useCategories();
  const [step, setStep] = useState(1);
  const [thumb, setThumb] = useState(null);
  const [hasDiscount, setHasDiscount] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (courseSelected) {
      setHasDiscount(courseSelected.discount ? true : false);
    } else {
      setHasDiscount(false);
    }
  }, [courseSelected]);

  useEffect(() => {
    setStep(1);
  }, [initialValues]);

  const handlePreviousStep = (minSteps) => {
    return () => {
      setStep((prevStep) => Math.max(minSteps, prevStep - 1));
    };
  };

  const handleNextStep = (maxSteps) => {
    return () => {
      setStep((prevStep) => Math.min(prevStep + 1, maxSteps));
    };
  };

  const handleChangeStep = (minStep, maxStep) => {
    return (newStep) => {
      if (newStep < minStep) return;
      if (newStep > maxStep) return;
      setStep(newStep);
    };
  };

  const handleChangeThumb = (cb) => {
    return (e) => {
      const file = e.target.files[0];
      if (!file) return;
      const formats = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
      if (!formats.includes(file.type)) return;
      setThumb(file);
      cb(e.target.name, `${file.name}-${file.type}`);
    };
  };

  const handleSubmit = ({ data, thumb }, { resetForm }) => {
    let res = null;

    if (courseSelected) {
      const formData = new FormData();
      const newCategory = categories.find(
        (category) => category.id === Number(data.categoryId)
      );
      const newData = {
        ...data,
        discount: data.discount || 0,
        categoryId: data.categoryId,
        category: newCategory,
        addressed_to: serializeList(data.addressed_to),
        you_learn: serializeList(data.you_learn),
        requirements: serializeList(data.requirements),
        modules:
          data?.modules?.map((modulo, index) => ({
            ...modulo,
            position: index + 1,
            classes: modulo?.classes?.map((clase, idx) => ({
              ...clase,
              position: idx + 1,
            }))
          })) || [],
      };
      const json = JSON.stringify(newData);
      const blob = new Blob([json], { type: "application/json" });

      formData.append("data", blob);
      formData.append("thumb", thumb);

      res = updateCourse(data.id, formData);
      dispatch(
        setMessage({
          type: "promise",
          message: {
            success: "Curso actualizado exitosamente",
            error: "Ha ocurido un error",
          },
          promise: res,
        })
      );
    } else {
      const formData = new FormData();
      const newData = {
        ...data,
        isPublicated: true,
        certification: true,
        discount: data.discount || 0,
        addressed_to: serializeList(data.addressed_to),
        you_learn: serializeList(data.you_learn),
        requirements: serializeList(data.requirements),
        modules:
          data?.modules?.map((modulo, index) => ({
            ...modulo,
            position: index + 1,
            classes: modulo?.classes?.map((clase, idx) => ({
              ...clase,
              position: idx + 1,
            }))
          })) || [],
      };
      const json = JSON.stringify(newData);
      const blob = new Blob([json], { type: "application/json" });

      formData.append("data", blob);
      formData.append("thumb", thumb);

      // console.log(newData);
      // return;

      res = handleAddSubmit(formData);
      dispatch(
        setMessage({
          type: "promise",
          message: {
            success: "Curso creado exitosamente",
            error: "Ha ocurido un error",
          },
          promise: res,
        })
      );
    }

    res
      .then(() => {
        if (courseSelected) {
          handleSelectCourse(null);
        }
        window.bootstrap.Modal.getInstance(
          document.getElementById(courseIdModal)
        ).hide();
        setStep(1);
        setThumb(null);
        resetForm();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleAddSubmit = (data) => {
    return addCourse(data);
  };

  const renderModuleArrayErrors = (errors) =>
    typeof errors.modules === "string" && (
      <small className="form-error-message">{errors.modules}</small>
    );

  const handleHasDiscount = (e, setFieldValue) => {
    if (!e.target.checked) setFieldValue("discount", "");
    setHasDiscount(e.target.checked);
  };

  const handleCancel = (resetForm) => {
    if (courseSelected) {
      handleSelectCourse(null);
    } else {
      resetForm();
    }
    setHasDiscount(false);
  };

  return {
    courseSchema,
    initialValues,
    newModule,
    categories,
    hasDiscount,
    handleSubmit,
    renderModuleArrayErrors,
    handleHasDiscount,
    handleCancel,
    users,
    step,
    thumb,
    handlePreviousStep,
    handleNextStep,
    handleChangeStep,
    handleChangeThumb,
  };
}
