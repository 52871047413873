import axios from "./axios.service";

export const getUsersCoursesReports = () => {
  return axios.get("/course/get_users_courses");
};

export const getCoursesReports = (year, stateId) => {
  return axios.get(`/report/courses?year=${year}${stateId ? `&state=${stateId}` : ""}`);
};

export const updateUserCourseAttemps = (courseId, userId) => {
  return axios.patch(`/course/${courseId}/user/${userId}`);
};
