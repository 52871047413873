import React, { useState } from 'react';
import { Pagination } from '../../../../components';
import { useEvent } from '../../../../hooks/useEvents';

function getFormatDate(date) {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const currentDate = new Date(date);
  const month = currentDate.getMonth();
  const day = currentDate.getDate() + 1;

  return { month: months[month], day };
}

function formatTime(time = '00:00') {
  const date = time.split(':');
  let hours = date[0];
  let minutes = date[1];
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return time !== '' ? strTime : '';
}

export const EventList = ({ eventIdModal, handleSelectEvent }) => {
  const { events, deleteEvents } = useEvent();

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  return (
    <div className='events_list'>
      <div className='events_list-grid'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre del Evento</th>
              <th>Impartido por</th>
              <th>Fecha</th>
              <th>Lugar</th>
              <th>Hora de Inicio</th>
              <th>Hora Final</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {events && events.length ? (
              events.map((event, index) => {
                const { month, day } = getFormatDate(event.date);
                return (
                  index >= initial &&
                  index <= end && (
                    <tr key={event.id}>
                      <th data-label='#' scope='row'>
                        {index + 1}
                      </th>
                      <td data-label='Nombre del Evento:'>{event.title}</td>
                      <td data-label='Impartido por:'>{event.imparted || 'No data'}</td>
                      <td data-label='Fecha:'>
                        {event.date !== '' && event.date !== null ? `${month}, ${day}` : ''}
                      </td>
                      <td data-label='Lugar:'>{event.location || 'No data'}</td>
                      <td data-label='Hora de Inicio'>
                        {event.hour_start !== '' &&
                          event.hour_start !== null &&
                          formatTime(event.hour_start)}
                      </td>
                      <td data-label='Hora Final'>
                        {event.hour_end !== '' &&
                          event.hour_end !== null &&
                          formatTime(event.hour_end)}
                      </td>
                      <td className='table__actions'>
                        <button
                          type='button'
                          className='btn btn-light btn-small mr-1'
                          data-bs-toggle='modal'
                          data-bs-target={`#${eventIdModal}`}
                          onClick={() => handleSelectEvent(event)}
                        >
                          <i className='uil uil-pen' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-light btn-small'
                          onClick={() => deleteEvents(event.id)}
                        >
                          <i className='uil uil-trash-alt' />
                        </button>
                      </td>
                    </tr>
                  )
                );
              })
            ) : (
              <tr>
                <td colSpan='8' align='center'>
                  No hay eventos disponibles
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={events?.length} />
    </div>
  );
};
