import React, { useEffect, useState } from 'react';

export function SearchBar({ handleSearchUser }) {
  const [userSearch, setUserSearch] = useState('');

  const handleOnChange = (e) => setUserSearch(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchUser(userSearch);
  };

  useEffect(() => {
    if (!userSearch) handleSearchUser('');
  }, [userSearch, handleSearchUser]);

  return (
    <form onSubmit={handleSubmit}>
      <div className='input-group mt-3 p-0 col-md-6'>
        <input
          style={{ boxShadow: 'none' }}
          type='search'
          className='form-control'
          placeholder='Nombre del usuario'
          value={userSearch}
          onChange={handleOnChange}
        />
        <button type='submit' className='btn btn-dark'>
          <i className='fas fa-search'></i>
        </button>
      </div>
    </form>
  );
}
