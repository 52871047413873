import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

import { logo } from "../../../assets/img";

import { sendEmailRecoverPassword } from "../../../services";

const url = window.location.hostname;

const initialState = {
  email: "",
  url:
    url.includes("develop") || url.includes("localhost")
      ? "https://develop-admin.capacitacionps.com"
      : "https://admin.capacitacionps.com",
};

export function EmailForm({ handleChangeToLogIn }) {
  const [data, setData] = useState(initialState);

  const submitButtonRef = useRef(null);
  const emailInputRef = useRef(null);

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    submitButtonRef.current.setAttribute("disabled", "");

    const sendEmail = sendEmailRecoverPassword(data);

    sendEmail
      .then(() => {
        setData(initialState);
        emailInputRef.current.blur();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        submitButtonRef.current.removeAttribute("disabled");
      });

    toast.promise(
      sendEmail,
      {
        loading: "Espera...",
        success: "Hemos enviado un correo de recuperación",
        error: "Ha ocurrido un error",
      },
      {
        duration: 5000,
      }
    );
  };

  return (
    <form className="login__form" onSubmit={handleOnSubmit}>
      <div className="login__logo">
        <img src={logo} alt="Proceso Seguro" className="login__logo-img" />
      </div>
      <div className="login__input-group">
        <input
          ref={emailInputRef}
          id="email"
          name="email"
          type="text"
          className="login__input"
          value={data.email}
          onChange={({ target }) => handleOnChange(target)}
          required={true}
        />
        <label htmlFor="email" className="login__input-label">
          Correo Electrónico
        </label>
      </div>

      <div className="login__link-recovery d-flex justify-content-between">
        <Link to="/login" onClick={handleChangeToLogIn}>
          Regresar
        </Link>
        <button ref={submitButtonRef} className="btn btn-dark login-button">
          Enviar
        </button>
      </div>
    </form>
  );
}
