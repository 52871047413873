import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { useExamForm } from '../../hooks/useExamForm';
import { QuizQuestions } from '../quiz-questions';
import './styles.scss';

export const AddEdit = ({ examIdModal, examSelected, handleSelectExam }) => {
  const {
    examSchema,
    initialValues,
    question,
    handleSubmit,
    renderquizQuestionArrayErrors,
    handleCancel,
  } = useExamForm({ examIdModal, examSelected, handleSelectExam });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={examSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, resetForm }) => (
        <Form className='exam__form'>
          <div className='form-group'>
            <label className='exam__form-label' htmlFor='exam_form-title'>
              <i className='uil uil-edit-alt'></i>Título
            </label>
            <Field
              id='exam_form-title'
              className='exam_form-title form-control'
              name='name'
              type='text'
            />
            <ErrorMessage name='name' component='small' className='form-error-message' />
          </div>

          <FieldArray
            name='quizQuestions'
            render={(arrayHelpersQuestion) => (
              <>
                {values?.quizQuestions && values?.quizQuestions?.length > 0
                  ? values?.quizQuestions?.map((element, index) => (
                      <QuizQuestions
                        key={index}
                        data={element}
                        index={index}
                        errors={errors}
                        arrayHelpersQuestion={arrayHelpersQuestion}
                      />
                    ))
                  : null}
                <div className='button-add-form'>
                  {renderquizQuestionArrayErrors(errors)}
                  <button
                    type='button'
                    className='btn btn-link'
                    onClick={() => arrayHelpersQuestion.push(question)}
                  >
                    <i className='uil uil-folder-plus'></i> Agregar Pregunta
                  </button>
                </div>
              </>
            )}
          />

          <button type='submit' className='btn btn-dark exam__form-submit mt-3 mr-3'>
            Guardar
          </button>
          <button
            type='button'
            className='btn btn-light exam__form-submit mt-3'
            onClick={() => handleCancel(resetForm)}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Formik>
  );
};
