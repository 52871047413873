import React, { useState } from 'react';
import { AddEdit, CourseList, CoursesHeader } from './modules';
import { MainLayout, Modal } from '../../layouts';
import './styles.scss';
import { AttachDocuments } from './modules/attachDocuments';

export const Courses = () => {
  const courseIdModal = 'course-modal';
  const documentsIdModal = 'attach-documents-modal';
  const [courseSelected, setCourseSelected] = useState(null);

  const handleSelectCourse = (course) => setCourseSelected(course);

  return (
    <MainLayout>
      <CoursesHeader courseIdModal={courseIdModal} documentsIdModal={documentsIdModal} handleSelectCourse={handleSelectCourse} />

      <CourseList courseIdModal={courseIdModal} handleSelectCourse={handleSelectCourse} />

      <Modal id={courseIdModal} title={courseSelected ? 'Editar Curso' : 'Agregar Curso'}>
        <AddEdit
          courseIdModal={courseIdModal}
          courseSelected={courseSelected}
          handleSelectCourse={handleSelectCourse}
        />
      </Modal>

      <Modal id={documentsIdModal} title="Anexar Documentos">
        <AttachDocuments documentsIdModal={documentsIdModal} />
      </Modal>
    </MainLayout>
  );
};
