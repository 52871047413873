import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStates } from '../../../services';
import { getCoursesReport } from '../../../store/actions';

export function useCourses() {
  const coursesReports = useSelector((state) => state?.reports?.coursesReports);
  const [year, setYear] = useState(new Date().getFullYear());
  const [stateId, setStateId] = useState('');
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();

  const handlePrevYear = () => setYear((prevYear) => prevYear - 1);
  const handleNextYear = () => setYear((prevYear) => prevYear + 1);
  const handleChange = (e) => setStateId(e.target.value);

  useEffect(() => {
    let isMounted = true;

    getStates()
      .then((states) => {
        if (isMounted) setStates(states);
      })
      .catch((err) => console.error(err));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) dispatch(getCoursesReport(year, stateId));

    return () => (isMounted = false);
  }, [year, stateId, dispatch]);

  return {
    coursesReports,
    year,
    handlePrevYear,
    handleNextYear,
    stateId,
    handleChange,
    states,
  };
}
