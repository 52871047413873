import axios from 'axios';
import { store } from '../store';
import { logout, setMessage } from '../store/actions';
import { getConfig, getUserToken, history, removeUserFromLocalStorage } from '../utils';

// Config object for baseURL and headers(User Token)
// import { store } from '../redux/configStore';
// import { setLoading } from '../redux/actions';
// import { error as messageError } from './message';

let instance = axios.create({ baseURL: process.env.REACT_APP_URL_API });

let numLoadings = 0;
const requestHandler = (request) => {
  request.headers = getConfig();
  numLoadings++;
  // store.dispatch(setLoading(true));
  return request;
};
const responseHandler = (response) => {
  if (--numLoadings === 0) {
    // store.dispatch(setLoading(false));
  }

  if (response && response.data) {
    return response.data;
  }

  return response;
};
const errorHandler = (err) => {
  if (!--numLoadings) {
    // store.dispatch(setLoading(false));
  }
  // messageError('Falló tu conexión, vuelve a intentarlo', 3000);
  const token = getUserToken();

  if (err.response.status === 401 && token) {
    removeUserFromLocalStorage();

    const modals = document.querySelectorAll('div[id$=-modal]');

    if (modals.length > 0) {
      modals.forEach((modal) => {
        const modalInstance = window.bootstrap.Modal.getInstance(modal);
        if (modalInstance) modalInstance.hide();
      });
    }

    store.dispatch(logout());

    history.push('/login');

    store.dispatch(
      setMessage({
        type: 'error',
        message: 'Sesión expirada. Inicie sesión nuevamente',
      })
    );
  } else if (err.response.status === 403) {
    store.dispatch(
      setMessage({
        type: 'error',
        message: 'No tienes permisos para esta acción',
      })
    );
  }

  return Promise.reject(err);
};

instance.interceptors.request.use((request) => requestHandler(request));
instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default instance;
