import './styles.scss';

export const Menu = ({ setIsExpanded, expand }) => {
  return (
    <button
      className={expand ? 'dashboard-topbar-menu active' : 'dashboard-topbar-menu'}
      onClick={setIsExpanded}
    >
      <span className='dashboard-topbar-menu-item' />
      <span className='dashboard-topbar-menu-item' />
      <span className='dashboard-topbar-menu-item' />
    </button>
  );
};
