import { User } from './user';
import { NavList } from './list';
import './styles.scss';

export const Sidebar = ({ expand }) => {
  return (
    <aside className={expand ? 'dashboard__sidebar expanded' : 'dashboard__sidebar'}>
      <User />
      <NavList />
    </aside>
  );
};
