import React, { useState } from 'react';
import { Pagination } from '../../../../components';
import { useCourses } from '../../../../hooks/useCourses';

export const CourseList = ({ courseIdModal, handleSelectCourse }) => {
  const { courses, deleteCourse } = useCourses();

  // Pagination
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  return (
    <div className='course_list'>
      <div className='courses_list-grid'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre del Curso</th>
              <th>Categoría</th>
              <th>Precio</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {courses && courses.length ? (
              courses.map(
                (course, index) =>
                  index >= initial &&
                  index <= end && (
                    <tr key={index + 1 + 'curso'}>
                      <th data-label='#' scope='row'>
                        {index + 1}
                      </th>
                      <td data-label='Nombre del curso:'>{course?.title}</td>
                      <td data-label='Categoría:'>
                        {course?.category ? course?.category.name : null}
                      </td>
                      <td data-label='Precio:'>$ {course?.price}</td>
                      <td className='table__actions'>
                        <button
                          type='button'
                          className='btn btn-light btn-small mr-1'
                          data-bs-toggle='modal'
                          data-bs-target={`#${courseIdModal}`}
                          onClick={() => handleSelectCourse(course)}
                        >
                          <i className='uil uil-pen' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-light btn-small'
                          onClick={() => deleteCourse(course.id)}
                        >
                          <i className='uil uil-trash-alt' />
                        </button>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td colSpan='6' align='center'>
                  No hay cursos disponibles
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={courses?.length} />
    </div>
  );
};
