import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useUserForm from '../../hooks/useUserForm';
import './styles.scss';
import { ROLES } from '../../../../utils';

export const AddEdit = ({ userIdModal, userSelected, handleSelectUser }) => {
  const { userFormSchema, initialValues, roles, handleSubmit, handleCancel } = useUserForm({
    userIdModal,
    userSelected,
    handleSelectUser,
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={userFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm }) => (
        <Form className='user__form'>
          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-firstname'>
              <i className='uil uil-edit-alt'></i> Nombres
            </label>
            <Field
              id='user_form-firstname'
              className='user_form-firstname form-control'
              type='text'
              name='name'
            />
            <ErrorMessage name='name' component='small' className='form-error-message' />
          </div>

          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-lastname'>
              <i className='uil uil-edit-alt'></i> Apellidos
            </label>
            <Field
              id='user_form-lastname'
              className='user_form-lastname form-control'
              type='text'
              name='lastname'
            />
            <ErrorMessage name='lastname' component='small' className='form-error-message' />
          </div>

          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-username'>
              <i className='fa-solid fa-user'></i> Nombre de usuario
            </label>
            <Field
              id='user_form-username'
              className='user_form-username form-control'
              type='text'
              name='username'
            />
            <ErrorMessage name='username' component='small' className='form-error-message' />
          </div>

          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-avatar'>
              <i className='fa-solid fa-camera'></i> Imagen de Perfil
            </label>
            <Field
              id='user_form-avatar'
              className='user_form-avatar form-control'
              type='text'
              name='avatar'
            />
            <ErrorMessage name='avatar' component='small' className='form-error-message' />
          </div>

          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-email'>
              <i className='uil uil-envelope-alt'></i> Correo Electronico
            </label>
            <Field
              id='user_form-email'
              className='user_form-email form-control'
              type='text'
              name='email'
            />
            <ErrorMessage name='email' component='small' className='form-error-message' />
          </div>

          {!userSelected && (
            <div className='form-group'>
              <label className='user__form-label' htmlFor='user_form-password'>
                <i className='fa-solid fa-key'></i> Contraseña
              </label>
              <Field
                id='user_form-password'
                className='user_form-password form-control'
                type='password'
                name='password'
              />
              <ErrorMessage name='password' component='small' className='form-error-message' />
            </div>
          )}

          <div className='form-group'>
            <label className='user__form-label' htmlFor='user_form-role'>
              <i className='uil uil-user-plus'></i> Rol
            </label>
            <Field
              as='select'
              id='user_form-role'
              className='user_form-role form-control'
              type='text'
              name='roleId'
            >
              <option value=''>Seleccione un rol</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Field>
            <ErrorMessage name='roleId' component='small' className='form-error-message' />
          </div>

          {Number(values.roleId) === ROLES.instructor && (
            <div className='form-group'>
              <label className='user__form-label' htmlFor='user_form-role'>
                <i className='fa-solid fa-signature'></i> Firma del instructor
              </label>
              <Field
                id='user_form-intructor-signature'
                className='user_form-role form-control'
                type='text'
                name='instructorSignature'
              />
              <ErrorMessage
                name='instructorSignature'
                component='small'
                className='form-error-message'
              />
            </div>
          )}

          <button type='submit' className='btn btn-dark user_form-submit mt-3 mr-3'>
            Guardar
          </button>
          <button
            type='button'
            className='btn btn-light user_form-submit mt-3'
            onClick={() => {
              handleCancel(resetForm);
            }}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Formik>
  );
};
