import React from 'react';

export function ExamsHeader({ examIdModal, handleSelectExam }) {
  return (
    <div className='exams__header'>
      <h2 className='exams__title'>
        <i className='uil uil-file-bookmark-alt'></i> Examenes
      </h2>

      <div className='row'>
        <div className='exams__add'>
          <button
            onClick={() => handleSelectExam(null)}
            className='btn btn-dark exams__add-btn m-2'
            data-bs-toggle='modal'
            data-bs-target={`#${examIdModal}`}
          >
            <i className='uil uil-plus courses__add-icon'></i>{' '}
            <span className='exams__add-text'>Agregar Examen</span>
          </button>
        </div>
      </div>
    </div>
  );
}
