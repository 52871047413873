import React from "react";
import { useDispatch } from "react-redux";
import { resetUserCourseAttemps } from "../../../../../store/actions";

export function UserCourseData({ user, course }) {
  const dispatch = useDispatch();

  const handleResetUserCourseAttemps = () => {
    dispatch(resetUserCourseAttemps(course?.course_id, user?.id));
  };

  return (
    <>
      <tr className="course-data">
        <td className="empty-course-field" />
        <td data-label="Usuario:" className="course-user-data">
          {user?.name} {user.last_name}
        </td>
        <td data-label="Empresa:" className="course-user-data">
          {user.company}
        </td>
        <td data-label="Última Conexión:" className="course-user-data">
          {user.last_login || "-"}
        </td>
        <td data-label="Curso:">{course?.course_title}</td>
        <td data-label="Avance">{course?.percentage_course}</td>
        <td data-label="Calificación">{course?.qualification_course}</td>
      </tr>

      <tr className="course-data">
        <th className="empty-course-field" />
        <th className="course-user-data">Intentos</th>
        <th className="course-user-data">Acciones</th>
      </tr>

      <tr className="course-data">
        <td className="empty-course-field" />
        <td data-label="Intentos:">{course?.attemps}</td>
        <td data-label="Acciones:">
          <button
            // disabled={course?.attemps < 2}
            onClick={handleResetUserCourseAttemps}
            className="actions-attemps"
          >
            Reiniciar
          </button>
        </td>
      </tr>
    </>
  );
}
