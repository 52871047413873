import { history, ROLES } from '../../utils';
import { getAllRegisters } from './registers.actions';
import { getUsersCoursesReport } from './reports.actions';
import { getAllCourses } from './courses.actions';
import { getAllEvents } from './events.actions';
import { signInService } from '../../services';
import { getAllExams } from './exams.actions';
import { getAllUsers } from './users.actions';
import { setMessage } from './app.actions';
import { actions } from './actions';

export const fetchInitalData = () => {
  return async function (dispatch) {
    try {
      Promise.all([
        await dispatch(getAllUsers()),
        await dispatch(getAllCourses()),
        await dispatch(getAllRegisters()),
        await dispatch(getAllExams()),
        await dispatch(getAllEvents()),
        await dispatch(getUsersCoursesReport()),
      ]);
    } catch (err) {
      console.error(err);
    }
  };
};

export const login = (user) => ({ type: actions.LOGIN, payload: user });

export const logout = () => ({ type: actions.LOGOUT });

export const signIn = (credentials) => {
  return function (dispatch) {
    return signInService(credentials).then((userAuth) => {
      if (userAuth.user.roleId === ROLES.user || userAuth.user.roleId === ROLES.instructor) {
        return Promise.reject({ status: 403, message: 'Not admin role' });
      }

      const user = JSON.stringify(userAuth);

      localStorage.setItem('PROCESO_SEGURO_ADMINISTRATOR_USER_APP', user);

      dispatch(login(userAuth.user));

      dispatch(
        setMessage({
          type: 'wellcome',
          img: userAuth.user.avatar ? true : false,
          avatar: userAuth.user.avatar
            ? userAuth.user.avatar
            : `${userAuth.user.name} ${userAuth.user.lastname}`,
          message: `Bienvenido/a ${userAuth.user.username}!`,
        })
      );

      dispatch(fetchInitalData());

      history.push('/');
    });
  };
};
