import React, { useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useCertificates } from '../../../../hooks/useCertificates';
import { CertPdf } from '../../../certificate/certPdf';
import { FormDC3 } from '../../../certificate/formDC3';
import './styles.scss';

export const CertificateList = () => {
  const { certificates } = useCertificates();

  const [show, setShow] = useState(false);
  const [info, setInfo] = useState();
  const [showF, setShowF] = useState(false);
  const [infoF, setInfoF] = useState();

  const showCertificate = (certificate) => {
    setShow(true);
    setInfo(certificate);
  };

  const showForm = (certificate) => {
    setShowF(true);
    setInfoF(certificate);
  };

  return (
    <>
      {show && (
        <div className='pdf'>
          <button
            type='button'
            className='btn btn-warning btn-small'
            onClick={() => setShow(false)}
          >
            Cerrar PDF
          </button>

          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <CertPdf certificates={info} />
          </PDFViewer>
        </div>
      )}
      {showF && (
        <div className='pdf'>
          <button
            type='button'
            className='btn btn-warning btn-small'
            onClick={() => setShowF(false)}
          >
            Cerrar PDF
          </button>

          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <FormDC3 certificates={infoF} />
          </PDFViewer>
        </div>
      )}

      <div className='certificate__list'>
        <div className='certificate_list-grid'>
          <table className='table'>
            <thead>
              <tr>
                <th>Diplomas</th>
                <th>FormDC-3</th>
              </tr>
            </thead>

            <tbody>
              {certificates.length > 0 ? (
                <tr>
                  <td>
                    <button
                      type='button'
                      className='btn btn-light btn-small'
                      data-bs-toggle='modal'
                      onClick={() => showCertificate(certificates)}
                    >
                      Ver Diplomas
                    </button>
                  </td>
                  <td>
                    <button
                      type='button'
                      className='btn btn-light btn-small'
                      data-bs-toggle='modal'
                      onClick={() => showForm(certificates)}
                    >
                      Ver FormDC-3
                    </button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
