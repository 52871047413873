import { actions } from '../actions/actions';
import { EXPANDED, LOADING, INFOMODAL, REMOVE_INFOMODAL } from '../constants/app.constants';

const initialState = {
  isLoading: false,
  expand: false,
  message: null,
  infoModal: {
    type: '',
    handleClose: '',
    autoClose: false,
    showingTime: 3000,
    action: null,
    message: '',
    title: '',
  },
};

export const app = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case EXPANDED:
      return {
        ...state,
        expand: !state.expand,
      };

    case actions.SET_MESSAGE:
      return {
        ...state,
        message: payload,
      };

    case INFOMODAL:
      const { type, handleClose, autoClose, showingTime, actionModal, message, title } = payload;
      return {
        ...state,
        infoModal: {
          type,
          handleClose,
          autoClose,
          showingTime,
          action: actionModal,
          message,
          title,
        },
      };

    case REMOVE_INFOMODAL:
      return {
        ...state,
        infoModal: initialState.infoModal,
      };

    default:
      return state;
  }
};
