import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AppRouter } from './routes'
import { store } from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/styles.scss'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <AppRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
