import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { logo } from '../../../assets/img';

const initialState = {
  email: '',
  password: '',
};

export function LoginForm({ handleChangeToRecoverPassword }) {
  const [data, setData] = useState(initialState);
  const [signInError, setSignInError] = useState('');
  const [authError, setAuthError] = useState('');

  const { logIn } = useAuth();

  const handleOnChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
    if (signInError) setSignInError('');
    if (authError) setAuthError('');
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    logIn(data).catch((err) => {
      console.error(err.message);

      if (err.status === 403) {
        setAuthError('Acceso válido sólo para administradores y autores.');
      } else {
        setSignInError('Credenciales incorrectas.');
      }
    });
  };

  return (
    <form className='login__form' onSubmit={handleOnSubmit}>
      <div className='login__logo'>
        <img src={logo} alt='Proceso Seguro' className='login__logo-img' />
      </div>
      <div className='login__input-group'>
        <input
          id='email'
          name='email'
          type='text'
          className='login__input'
          value={data.email}
          onChange={({ target }) => handleOnChange(target)}
          required={true}
        />
        <label htmlFor='email' className='login__input-label'>
          Correo Electrónico
        </label>
      </div>
      <div className='login__input-group'>
        <input
          id='password'
          name='password'
          type='password'
          className='login__input'
          value={data.password}
          onChange={({ target }) => handleOnChange(target)}
          required={true}
        />
        <label htmlFor='password' className='login__input-label'>
          Contraseña
        </label>
      </div>

      {signInError && (
        <div className='login__error'>
          <small className='text-danger'>{signInError}</small>
        </div>
      )}

      {authError && (
        <div className='login__error'>
          <small className='text-danger'>{authError}</small>
        </div>
      )}

      <div className='login__link-recovery'>
        <Link to='/login' onClick={handleChangeToRecoverPassword}>
          Olvidé mi contraseña
        </Link>
      </div>

      <div className='login__form-btn'>
        <button className='btn btn-dark login-button'>Ingresar</button>
      </div>
    </form>
  );
}
