import React, { useEffect, useState } from 'react';
import { CATEGORIES } from '../../../../utils';

export function RegistersHeader({
  registerIdModal,
  handleSelectRegister,
  handleSearchRegister,
  handleChangeCategory,
}) {
  const [registerSearch, setRegisterSearch] = useState('');

  const handleOnChange = (e) => setRegisterSearch(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchRegister(registerSearch);
  };

  useEffect(() => {
    if (!registerSearch) handleSearchRegister('');
  }, [registerSearch, handleSearchRegister]);

  return (
    <div className='registers__header'>
      <h2 className='registers__title'>
        <i className='uil uil-qrcode-scan' /> Registros
      </h2>
      <div className='registers'>
        <div className='mr-3'>
          <button
            className='btn btn-warning mb-1 mr-3'
            onClick={() => handleChangeCategory(CATEGORIES.proteccionCivil)}
          >
            Protección civil
          </button>
          <button
            className='btn btn-warning'
            onClick={() => handleChangeCategory(CATEGORIES.secretariaTrabajo)}
          >
            Secretaría del trabajo
          </button>
        </div>
        <div>
          <button
            onClick={() => handleSelectRegister(null)}
            className='btn btn-dark users__add-btn'
            data-bs-toggle='modal'
            data-bs-target={`#${registerIdModal}`}
          >
            <i className='uil uil-plus users__add-icon'></i>
            <span className='certificates__add-text'>Agregar Registro</span>
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='input-group mt-3 p-0 col-md-6'>
          <input
            style={{ boxShadow: 'none' }}
            type='search'
            className='form-control'
            placeholder='Número de Registro'
            value={registerSearch}
            onChange={handleOnChange}
          />
          <button type='submit' className='btn btn-dark'>
            <i className='fas fa-search'></i>
          </button>
        </div>
      </form>
    </div>
  );
}
