import moment from 'moment';
import 'moment/locale/es';

export const formatDate = (date = new Date(), format = 'LLL') => {
  moment.locale('es');
  const newDate = moment(date)
    .format(format)
    .split(/\d+:\d+/)[0];

  return newDate;
}