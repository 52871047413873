import { useEffect, useState } from 'react';
import { getRoles } from '../services';

export function useRoles() {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    let isMounted = true;

    getRoles()
      .then((roles) => {
        if (isMounted) setRoles(roles);
      })
      .catch((err) => console.error(err.message));

    return () => (isMounted = false);
  }, []);

  return { roles };
}
