import React from "react";

import { RecoveryForm } from "./components/RecoveryForm";

import "../login/styles.scss";

export const Recovery = () => {
  return (
    <section className="login">
      <div className="container login__content">
        <RecoveryForm />
      </div>
    </section>
  );
};
