import { addRegister, deleteRegister, getRegisters, updateRegister } from '../../services';
import { actions } from './actions';

export const setRegisters = (registers) => ({ type: actions.SET_REGISTERS, payload: registers });

export const createRegister = (register) => {
  return function (dispatch) {
    return addRegister(register).then(() => dispatch(getAllRegisters()));
  };
};

export const putRegister = (id, register) => {
  return function (dispatch) {
    return updateRegister(id, register).then(() => dispatch(getAllRegisters()));
  };
};

export const getAllRegisters = () => {
  return function (dispatch) {
    return getRegisters().then((registers) => dispatch(setRegisters(registers)));
  };
};

export const removeRegister = (id, categoryId) => {
  return function (dispatch) {
    return deleteRegister(id, categoryId).then(() => dispatch(getAllRegisters()));
  };
};
