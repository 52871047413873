import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import uuid from "uuid/dist/v4";
import certPS1 from "../../assets/img/certPS1.jpg";
import arial from "../../assets/fonts/arial.ttf";
import arialbd from "../../assets/fonts/arialbd.ttf";
import arialbi from "../../assets/fonts/arialbi.ttf";
import { formatDate } from "../../utils";

Font.register({
  family: "Arial",
  fonts: [
    { src: arial },
    { src: arialbd, fontWeight: "bold" },
    { src: arialbi, fontWeight: "bold", fontStyle: "italic" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  body: {
    margin: 0,
    paddingBottom: -10,
  },
  imagen: {
    backgroundColor: "transparent",
    position: "relative",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
  },
  signature: {
    backgroundColor: "transparent",
    width: "230px",
    height: "125px",
    objectFit: "contain",
    position: "absolute",
    transform: "translateX(-50%)",
  },
  text: {
    fontFamily: "Arial",
    fontSize: 18,
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
});

// Create Document Component9
export const CertPdf = ({ certificates }) => {
  return (
    <Document>
      {certificates?.map((user) =>
        user?.dataRegister?.map(
          (dataRegister) => {
            if (!dataRegister) return false

            const startDate = new Date(new Date(user?.startDate).setDate(new Date(user?.startDate).getDate() + 1))
            const endDate = new Date(new Date(user?.endDate).setDate(new Date(user?.endDate).getDate() + 1))
            const daysDifference = endDate.getDate() - startDate.getDate()

            return (
              <Page
                key={`${user?.CURP}-${dataRegister?.course?.id}`}
                size="LETTER"
                orientation="landscape"
                style={styles.body}
              >
                <View wrap={false}>
                  <Image src={certPS1} alt="Proceso" style={styles.imagen} />

                  <Text
                    style={[
                      styles.text,
                      {
                        marginTop: "160",
                        fontSize: "22",
                      },
                    ]}
                  >
                    Se otorga el presente certificado a:
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        marginTop: "210",
                        fontSize: "22",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      },
                    ]}
                  >
                    {user?.Nombres} {user?.Apellidos}
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      { marginTop: "235", fontSize: "18", fontWeight: "bold", fontStyle: "italic" },
                    ]}
                  >
                    {user?.CURP}
                  </Text>

                  <Image
                    source={{ header: { "Access-Control-Allow-Origin": "*" } }}
                    src={dataRegister?.register?.instructor?.signatureInstructors?.[0]?.signature}
                    alt="Firma Instructor"
                    style={[styles.signature, { top: "408", left: "41.5%" }]}
                  />

                  <Text
                    style={[
                      styles.text,
                      {
                        lineHeight: 1.5,
                        width: "85%",
                        marginTop: "265",
                        left: "7.5%",
                      },
                    ]}
                  >
                    Por haber acreditado el curso de "{dataRegister?.course?.title}".{"\n"} El día{" "}
                    {daysDifference === 0 ? formatDate(endDate) : `${formatDate(startDate)} al ${formatDate(endDate)}`}, 
                    con una duración de {dataRegister?.course?.duration_course} horas,{"\n"}
                    impartido en las instalaciones de {user?.company?.business_name || "Proceso Seguro S.C."} con dirección en{"\n"}
                    {user?.Direccion
                      ? user?.Direccion
                      : "Av. Anillo Vial II Fray Junípero Serra Km 4+475, La Vista Residencial, C.P. 76146, Santiago de Querétaro, Querétaro"}
                  </Text>

                  <Text style={[styles.text, { marginTop: "540", fontSize: "14" }]}>
                    Lic. {dataRegister?.register?.instructor?.name}{" "}
                    {dataRegister?.RegisterInstructor?.instructor?.lastname}
                  </Text>
                  <Text style={[styles.text, { marginTop: "558", fontSize: "12" }]}>
                    {dataRegister?.register?.register}
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        marginTop: "570",
                        fontSize: "12",
                      },
                    ]}
                  >
                    N° Folio:{" "}
                    {dataRegister.folio_number
                      ? dataRegister.folio_number
                      : `PS-${uuid().substring(0, 10)}${dataRegister?.course?.id}`}
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        marginTop: "570",
                        fontSize: "12",
                        marginLeft: "225",
                      },
                    ]}
                  >
                    Vigencia: 1 año
                  </Text>
                </View>
              </Page>
            )
          }
        )
      )}
    </Document>
  );
};

/* import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import certPS1 from '../../assets/img/certPS1.jpg';

// Create styles
const styles = StyleSheet.create({
  body: {
    margin: 0,
    paddingBottom: -10,
  },
  imagen: {
    backgroundColor: '#fff',
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
  },
  signature: {
    width: '230px',
    height: '125px',
    objectFit: 'contain',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  text: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
});

// Create Document Component9
export const CertPdf = ({ certificates }) => {
  return (
    <Document>
      {certificates.map((user) =>
        user.dataRegister.map(
          (dataRegister) =>
            dataRegister && (
              <Page
                key={`${user.CURP}-${dataRegister?.course?.id}`}
                size='LETTER'
                orientation='landscape'
                style={styles.body}
              >
                <View wrap={false}>
                  <Image src={certPS1} alt='Proceso' style={styles.imagen} />

                  <Text style={[styles.text, { marginTop: '160', fontSize: '26' }]}>
                    {user.Nombres} {user.Apellidos}
                  </Text>
                  <Text style={[styles.text, { marginTop: '190', fontSize: '12' }]}>
                    {user.CURP}
                  </Text>
                  <Text style={[styles.text, { marginTop: '320', fontSize: '14' }]}>
                    Por haber acreditado el curso de
                  </Text>
                  <Text style={[styles.text, { marginTop: '335', fontSize: '14' }]}>
                    {dataRegister?.course?.title}
                  </Text>
                  <Text style={[styles.text, { marginTop: '350', fontSize: '14' }]}>
                    el {moment(user?.endDate || new Date()).format('DD-MM-YYYY')}
                  </Text>
                  <Text style={[styles.text, { marginTop: '365', fontSize: '14' }]}>
                    con una duracion de {dataRegister?.course?.duration_course} horas
                  </Text>
                  <Image
                    source={{ header: { 'Access-Control-Allow-Origin': '*' } }}
                    src={dataRegister?.register?.instructor?.signatureInstructors?.[0]?.signature}
                    alt='Firma Instructor'
                    style={[styles.signature, { top: '380', left: '42%' }]}
                  />

                  <Text style={[styles.text, { marginTop: '510', fontSize: '14' }]}>
                    Lic. {dataRegister?.register?.instructor?.name}{' '}
                    {dataRegister?.RegisterInstructor?.instructor?.lastname}
                  </Text>
                  <Text style={[styles.text, { marginTop: '528', fontSize: '12' }]}>
                    {dataRegister?.register?.register}
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        marginTop: '540',
                        fontSize: '12',
                      },
                    ]}
                  >
                    {`PS-${user?.CURP}-${dataRegister?.course?.id}`}
                  </Text>
                </View>
              </Page>
            )
        )
      )}
    </Document>
  );
}; */
